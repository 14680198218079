import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './Store';

export interface Profile {
  uid: string | null,
  displayName: string | null,
}

const initialState: Profile = {} as Profile;

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    initializeProfile: (_, action: PayloadAction<Profile | null>) => {
      if (action.payload) {
        return action.payload;
      }
      return {} as Profile;
    },
  },
});

export const { initializeProfile } = profileSlice.actions;

export const loadProfile = (profile: Profile | null): AppThunk => async dispatch => {
  if (profile && profile.uid) {
    dispatch(initializeProfile(profile));
  } else {
    dispatch(initializeProfile(null));
  }
};

export const selectProfile = (state: RootState) => state.profile

export default profileSlice.reducer;
