import { format, parse, parseISO, sub } from "date-fns";
import React from "react";
import firebase from "firebase/app";
import { Answer, Column, Convert, Prompt, Timestamp } from "./Models";
import color from "color";

export const colors: string[][] = [
  ["#F6B4B4", "#ECAAAA"],
  ["#FE8C8C", "#FA8B8B"],
  ["#FF6262", "#FF6565"],
  ["#E73131", "#FF4848"],
  ["#C6A5A5", "#D59696"],
  ["#FFCB46", "#FFD056"],
  ["#FFD976", "#BBA56E"],
  ["#E8AE1A", "#D6A935"],
  ["#FA9537", "#FF9432"],
  ["#FBB87A", "#FFB672"],
  ["#D7BC94", "#E7CBA0"],
  ["#E1B16B", "#DDB376"],
  ["#FFC26A", "#F6BE8B"],
  ["#FCAF40", "#FFB74F"],
  ["#BEC3AF", "#AEB794"],
  ["#A9B97C", "#BBD968"],
  ["#8BA83D", "#9EBE46"],
  ["#738B30", "#677A30"],
  ["#72BF35", "#64AE29"],
  ["#A2AF7D", "#A3B476"],
  ["#9FD2AA", "#98C5A1"],
  ["#78D38C", "#78D38C"],
  ["#3BBB57", "#29C34A"],
  ["#528D5F", "#458F55"],
  ["#127B29", "#238A39"],
  ["#74BDC1", "#80C5CA"],
  ["#65D9E0", "#65D9E0"],
  ["#14BDE2", "#2AC6E8"],
  ["#17979F", "#1C959D"],
  ["#086C73", "#11A188"],
  ["#9EAED7", "#9EAED7"],
  ["#8AABFF", "#9EAED7"],
  ["#4F7BEC", "#4F7BEC"],
  ["#1F5CFA", "#1F5CFA"],
  ["#284EB0", "#2E51AB"],
  ["#BEA8ED", "#BEA8ED"],
  ["#8670B4", "#8670B4"],
  ["#9571E2", "#9571E2"],
  ["#743DEB", "#743DEB"],
  ["#531CEF", "#6F3DFF"],
  ["#F4AAE0", "#F4AAE0"],
  ["#EC72CA", "#EC72CA"],
  ["#E34BB9", "#B74C99"],
  ["#A07193", "#9E638E"],
  ["#8E3A77", "#8E3A77"],
  ["#F6A887", "#F6A887"],
  ["#987566", "#987566"],
  ["#704634", "#704634"],
  ["#A44F2B", "#A44F2B"],
  ["#F15512", "#CC4106"],
  ["#98E3BF", "#98E3BF"],
  ["#A3A3A3", "#A3A3A3"],
  ["#737272", "#737272"],
  ["#575757", "#575757"],
  ["#9342B9", "#833AA6"],
  ["#7F7F9B", "#7F7F9B"],
  ["#807FBF", "#807FBF"],
  ["#66ADEE", "#4888C2"],
  ["#3F3BE9", "#3F3BE9"],
  ["#434182", "#606EB9"],
];

const colorMap = colors.reduce(
  (result: { [id: string]: { light: string; dark: string } }, el) => {
    const row = { light: el[0], dark: el[1] };
    result[el[0].toLowerCase()] = row;
    result[el[1].toLowerCase()] = row;
    return result;
  },
  {}
);

console.log(colorMap);

export interface BrightnessController {
  dark: boolean;
  setDark: (dark: boolean) => void;
}

export function logError(error: any) {
  console.log(`Error: ${error}`);
}

export function logInfo(message: string) {
  console.log(`Info: ${message}`);
}

export const { Provider: UserProvider, Consumer: UserConsumer } =
  React.createContext<firebase.User | null | undefined>(null);

export const {
  Provider: BrightnessControllerProvider,
  Consumer: BrightnessControllerConsumer,
} = React.createContext<BrightnessController | null>(null);

export function formatConceptualDate(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

export function parseConceptualDate(conceptual: string): Date {
  return parseISO(conceptual);
}

export function formatDisplayDate(date: Date): string {
  return format(date, "MMM dd, yyyy");
}

export function formatMonth(date: Date): string {
  return format(date, "MMM");
}

export function formatDay(date: Date): string {
  return format(date, "dd");
}

export function formatWeekday(date: Date): string {
  return format(date, "EEEE");
}

export function formatYearMonth(date: Date): string {
  return format(date, "MMM, yyyy");
}

export function parseDisplayDate(display: string): Date {
  return parse(display, "MMM dd, yyyy", new Date());
}

export function conceptualToDisplayDate(conceptual: string): string {
  return formatDisplayDate(parseConceptualDate(conceptual));
}

export function conceptualToDisplayMonth(conceptual: string): string {
  return formatMonth(parseConceptualDate(conceptual));
}

export function conceptualToDisplayDay(conceptual: string): string {
  return formatDay(parseConceptualDate(conceptual));
}

export function conceptualToDisplayWeekday(conceptual: string): string {
  return formatWeekday(parseConceptualDate(conceptual));
}

export function conceptualToDisplayYearMonth(conceptual: string): string {
  return formatYearMonth(parseConceptualDate(conceptual));
}

export function displayToConceptualDate(display: string): string {
  return formatConceptualDate(parseDisplayDate(display));
}

export function cloneColumns(columns: Column[]): Column[] {
  return columns
    .map((c) => Convert.columnToJson(c))
    .map((j) => Convert.toColumn(j));
}

export function clonePrompts(prompts: { [key: string]: Prompt }): {
  [key: string]: Prompt;
} {
  const result: { [key: string]: Prompt } = {};
  for (let key in prompts) {
    result[key] = Convert.toPrompt(Convert.promptToJson(prompts[key]));
  }
  return result;
}

export function clonePrompt(prompt: Prompt): Prompt {
  return Convert.toPrompt(Convert.promptToJson(prompt));
}

export function cloneAnswer(answer: Answer): Answer {
  return Convert.toAnswer(Convert.answerToJson(answer));
}

export function getColor(
  dark?: boolean | null,
  bgColor?: string | null,
  opacity?: number | null
): string | undefined | null {
  if (!bgColor) {
    return bgColor;
  }

  return dark ?? false
    ? colorMap[bgColor.toLowerCase()]?.dark ?? bgColor
    : colorMap[bgColor.toLowerCase()]?.light ?? bgColor;
}

export function getTextColor(
  dark?: boolean | null,
  bgColor?: string | null
): string {
  return dark
    ? color(getColor(dark, bgColor) ?? "black")
        .lighten(0.2)
        .alpha(0.8)
        .string()
    : color(getColor(dark, bgColor) ?? "black")
        .darken(0.4)
        .saturate(0.3)
        .string();
}

export function timestampToMs(ts?: Timestamp | null): number {
  if (!ts || !ts.seconds || !ts.nanoseconds) {
    return new Date().valueOf();
  }
  return ts.seconds * 1000 + ts.nanoseconds / 1000000;
}

export function capitalize(s: string): string {
  return (s && s[0].toUpperCase() + s.slice(1)) || "";
}

export function lastestAnswer(
  answers?: Answer[] | null
): Answer | null | undefined {
  if (!answers || answers.length === 0) {
    return;
  }
  if (answers.length === 1) {
    return answers[0];
  }

  let result = answers[0];
  for (let answer of answers) {
    if (
      !result.created_at ||
      (answer.created_at && answer.created_at > result.created_at)
    ) {
      result = answer;
    }
  }

  return result;
}

export function patchAnswerEmptyField(
  docs: firebase.firestore.DocumentSnapshot<Answer>[]
) {
  docs.forEach((d) => {
    const data = d.data();
    if (!data) {
      return;
    }

    const text = data?.text?.trim() ?? "";
    if (text === "" && data.empty !== true) {
      // set to true
      d.ref.update({ empty: true });
    } else if (text !== "" && data.empty !== false) {
      // set to false
      d.ref.update({ empty: false });
    }

    // do nothing
  });
}

export function countStreaks(
  answers: string[],
  conceptualDate: string,
  answerOnConceptualDate: string
): number {
  let base = answerOnConceptualDate === "" ? 0 : 1;
  let date = sub(parseConceptualDate(conceptualDate), { days: 1 });
  const answerSet = new Set<string>(answers);
  for (let i = 0; i < answers.length; i++) {
    const currentDate = formatConceptualDate(date);
    if (!answerSet.has(currentDate)) {
      return base;
    }
    base += 1;
    date = sub(date, { days: 1 });
  }
  return base;
}
