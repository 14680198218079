import { capitalize, Divider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { RoleType } from "../Models";

export const REMOVE_MEMBER_VALUE = "RemoveMember";

export default function RoleSelector({
  roles,
  selectedRole,
  onChangeRole,
  showRemove,
  disabled,
}: {
  showRemove: boolean;
  roles: RoleType[];
  selectedRole: RoleType;
  onChangeRole: (newRole: string) => void;
  disabled: boolean;
}) {
  function onChange(event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) {
    const value = event.target.value as string;
    onChangeRole(value);
  }

  return (
    <Select
      className="RoleSelect"
      value={selectedRole}
      onChange={onChange}
      displayEmpty
      inputProps={{ "aria-label": "Roles" }}
      variant="outlined"
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
      disabled={disabled}
    >
      {roles.map((r, i) => (
        <MenuItem key={r} value={r}>
          {capitalize(r)}
        </MenuItem>
      ))}
      {showRemove && <Divider />}
      {showRemove && (
        <MenuItem key="remove" value={REMOVE_MEMBER_VALUE}>
          Remove
        </MenuItem>
      )}
    </Select>
  );
}
