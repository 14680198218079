import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { navigate } from "@reach/router";
import firebase from "firebase/app";
import { createBoard } from "../db";
import { DialogContentText } from "@material-ui/core";

export default function NewBoardDialog(props: { open: boolean; user: firebase.User; onClose: () => void }) {
  const [creating, setCreating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [text, setText] = useState<string>("");

  async function onCreateBoard() {
    if (creating || !text || text === "") {
      return;
    }

    try {
      setCreating(true);
      setError(null);
      const id = await createBoard(props.user, text);
      if (id === "") {
        setError("Error creating board. Please refresh the page and try again");
        return;
      }
      navigate(`/app/${id}/edit`);
    } catch {
    } finally {
      setCreating(false);
    }
  }
  return (
    <Dialog
      className="Create-Board-Dialog"
      onClose={props.onClose}
      aria-labelledby="Create-Board-Dialog-Title"
      open={props.open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="Create-Board-Dialog-Title">Create a new board</DialogTitle>
      <DialogContent>
        {error && <DialogContentText>{error}</DialogContentText>}
        <TextField
          onChange={(event) => setText(event?.currentTarget?.value ?? "")}
          autoFocus
          id="name"
          label="Board name"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={onCreateBoard} color="primary" variant="contained" disableElevation>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
