import React, { MouseEvent, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import firebase from "firebase/app";

import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { BrightnessController, BrightnessControllerConsumer, UserConsumer } from "./Utils";
import { acceptBoardInvite, getBoardInvite, ignoreBoardInvite } from "./db";
import "./Members.sass";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";

function InviteAccept(props: {
  user: firebase.User;
  boardId?: string | null;
  inviteId?: string | null;
  code?: string | null;
  controller: BrightnessController | null;
}) {
  const dispatch = useDispatch();
  const [boardName, setBoardName] = useState<string | null>(null);
  const [accepting, setAccepting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!props.boardId || !props.code || !props.inviteId || !props.user) {
      return;
    }

    getBoardInvite(props.user, props.boardId, props.inviteId, props.code).then((d) => {
      if (d && d.boardName) {
        setBoardName(d.boardName);
      } else {
        // cannot find. show error
        setError("The invite doesn't exist or has expired.");
      }
    });
  }, [dispatch, props?.boardId, props.code, props.inviteId, props.user]);

  function onDone(event: MouseEvent<HTMLElement>) {
    navigate(`/app/${props.boardId ?? ""}`);
  }

  function onAccept() {
    if (!props.boardId || !props.code || !props.inviteId || !props.user) {
      return;
    }

    setAccepting(true);
    acceptBoardInvite(props.user, props?.boardId, props.inviteId, props.code)
      .then((d) => {
        setAccepting(false);
        if (d) {
          navigate(`/app/${props.boardId ?? ""}`);
        }
      })
      .catch(() => setAccepting(false));
  }

  function onIgnore() {
    if (!props.boardId || !props.code || !props.inviteId || !props.user) {
      return;
    }

    setAccepting(true);
    ignoreBoardInvite(props.user, props.boardId, props.inviteId, props.code)
      .then(() => {
        setAccepting(false);
        navigate("/app");
      })
      .catch(() => setAccepting(false));
  }

  return (
    <div className={`CanvasWrapper ${props.controller?.dark ?? false ? "Dark" : ""}`}>
      <div className="CanvasMenu">
        <img
          className="logo"
          src={`${process.env.PUBLIC_URL}/${props.controller?.dark ? "logo-dark.svg" : "logo.svg"}`}
          alt="Jot"
        />
        <div className="CanvasControls">
          <IconButton aria-label="exit" size="small" onClick={onDone}>
            <Close />
          </IconButton>
        </div>
      </div>
      <div className="Invite">
        {error && <div>{error}</div>}
        {boardName && (
          <div>
            <h2>You've been invited to {boardName}</h2>
            <Button color="primary" variant="contained" disableElevation onClick={onAccept} disabled={accepting}>
              Accept
            </Button>
            <Button onClick={onIgnore} disabled={accepting}>
              Ignore
            </Button>
          </div>
        )}
        {!boardName && !error && <div className="Loading">Loading...</div>}
      </div>
    </div>
  );
}

function InviteWithUser({
  board,
  invite,
  code,
}: { board?: string | null; invite?: string | null; code?: string | null } & RouteComponentProps) {
  return (
    <UserConsumer>
      {(user) => {
        return (
          <BrightnessControllerConsumer>
            {(controller) => {
              return (
                <InviteAccept user={user!} boardId={board} inviteId={invite} code={code} controller={controller} />
              );
            }}
          </BrightnessControllerConsumer>
        );
      }}
    </UserConsumer>
  );
}

export default InviteWithUser;
