import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from "firebase/app";
import { boardLatestAnswerForPromptBeforeDateQuery, boardLatestAnswerForPromptQuery, refToReflectionBoardAnswer, refToReflectionBoardAnswerByPromptAndDate, refToReflectionBoardAnswers, refToReflectionBoardAnswerVersions, refToReflectionBoardPrompt, serverTimestamp, } from '../db';
import { Answer, Prompt, PromptLifecycle, Timestamp } from '../Models';
import { cloneAnswer, logError } from '../Utils';
import { AppThunk, RootState } from './Store';

export interface Answers {
  conceptualDate?: string;
  answersByPromptId: { [key: string]: Answer[] };
  latestAnswersByPromptId: { [key: string]: Answer[] };
  fetching: boolean;
}

const initialState: Answers = { answersByPromptId: {}, fetching: true } as Answers;

export const answerSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    initializeAnswers: (_, action: PayloadAction<Answers>) => {
      return action.payload;
    },
    updateAnswers: (current, action: PayloadAction<Answer[]>) => {
      let newAnswersByPromptId: {
        [key: string]: Answer[];
      } = {};

      if (action.payload) {
        newAnswersByPromptId = action.payload.reduce((map, el: Answer) => {
          if (el.prompt_id) {
            // override if our version is newer, else use old version
            // const oldAnswer = current.answersByPromptId[el.prompt_id];
            // if (oldAnswer && oldAnswer.text !== el.text) {
            //   console.log(
            //     `${oldAnswer?.text}, ${el?.text}, ${oldAnswer?.updated_at?.seconds} < ${el?.updated_at?.seconds}`
            //   );
            // }
            // if (
            //   !oldAnswer ||
            //   !oldAnswer.updated_at?.seconds ||
            //   !el.updated_at?.seconds ||
            //   oldAnswer.updated_at.seconds < el.updated_at.seconds
            // ) {
            //   map[el.prompt_id] = el;
            // } else if (oldAnswer) {
            //   map[el.prompt_id] = oldAnswer;
            // }
            if (map[el.prompt_id]) {
              map[el.prompt_id].push(el);
            } else {
              map[el.prompt_id] = [el];
            }
          }
          return map;
        }, newAnswersByPromptId);
      }

      return {
        conceptualDate: current.conceptualDate,
        answersByPromptId: newAnswersByPromptId,
        latestAnswersByPromptId: current.latestAnswersByPromptId,
        fetching: false,
      };
    },
    updateAnswer: (current, action: PayloadAction<Answer>) => {
      // enforce answer must match current date.
      // if (current.conceptualDate === action.payload.conceptual_date && action.payload.prompt_id) {
      if (action.payload.prompt_id) {
        if (current.answersByPromptId[action.payload.prompt_id]) {
          current.answersByPromptId[action.payload.prompt_id].push(action.payload);
        } else {
          current.answersByPromptId[action.payload.prompt_id] = [action.payload];
        }
      }
      // }
      return current;
    },
    changeConceptualDate: (_, action: PayloadAction<string>) => {
      return {
        conceptualDate: action.payload,
        answersByPromptId: {},
        fetching: true,
      } as Answers;
    }
  },
});

export const { initializeAnswers, updateAnswers, updateAnswer, changeConceptualDate } = answerSlice.actions;

export const saveAnswer = (boardId: string, prompt: Prompt, conceptualDate: string, answerId: string | undefined | null, uid: string, text: string): AppThunk => async dispatch => {
  let firstDoc: firebase.firestore.DocumentSnapshot<Answer> | undefined | null;

  // if a given id is specified, then we update it.
  if (answerId) {
    const answerSnap = await refToReflectionBoardAnswer(boardId, answerId).get();
    firstDoc = answerSnap && answerSnap.data() ? answerSnap : null;
  }

  // if an answer is not specified or the answer cannot be found, we find it based on the lifecycle type.
  if (!firstDoc) {
    // if prompt is sticky, we find the latest answer that's before the conceptual date.
    if (prompt.lifecycle === PromptLifecycle.Manual) {
      // find the latest answer that's older than the given date.
      const answerSnaps = await boardLatestAnswerForPromptBeforeDateQuery(boardId, prompt.id!, conceptualDate).get();
      firstDoc = answerSnaps.docs.length > 0 ? answerSnaps.docs[0] : null;
    } else {
      // fetch answers on the conceptual date
      const answerSnaps: firebase.firestore.QuerySnapshot<Answer> = await refToReflectionBoardAnswerByPromptAndDate(boardId, prompt.id!, conceptualDate).get();
      firstDoc = answerSnaps.docs.length > 0 ? answerSnaps.docs[0] : null;
    }
  }

  let answer: Answer | undefined;

  // we don't delete answers because if we delete them, then we lose the history of answers.
  if (firstDoc) {
    // update
    try {
      await firstDoc.ref.update({ text, updated_at: serverTimestamp(), empty: text.trim() === "" });
      answer = firstDoc.data();
      answerId = firstDoc.id;
      if (answer) {
        answer.text = text;
      }
    } catch (error) {
      logError(error);
    }
  } else {
    // create

    // for sticky prompts, this may create a version in the past if conceptual date is not today.
    // it might the expected behavior, but can also be confusing.
    const newAnswer = {
      conceptual_date: conceptualDate,
      prompt_id: prompt.id,
      prompt_question: prompt.question,
      text,
      empty: text.trim() === "",
    } as Answer

    try {
      const newRef = await refToReflectionBoardAnswers(boardId).add(newAnswer);
      answerId = newRef.id;
      const newDoc = await newRef.get();
      const data = newDoc.data();
      if (data) {
        answer = data;
      }
    } catch (error) {
      logError(error);
    }
  }

  // finished updating or saving.

  if (!answer) {
    // nothing to do
    return;
  }

  // we have an answer

  if (!text || text.trim() === "") {
    // remove answer info from prompt.

    // get the latest answer of the prompt and set it.
    // in this function we may be updating answers in the past, so the answer object may not be the latest answer.
    const latestAnswerSnap: firebase.firestore.QuerySnapshot<Answer> = await boardLatestAnswerForPromptQuery(boardId, prompt.id!).get();
    let firstDoc = latestAnswerSnap.docs.length > 0 ? latestAnswerSnap.docs[0] : null;

    // if we can't find it, then it doesn't exist. save it as well.
    let additionalUpdate = {
      latest_answer_id: firstDoc?.id ?? null,
      latest_answer_conceptual_date: firstDoc?.data()?.conceptual_date ?? null,
    };

    // remove answer from prompt array
    // TODO: if there are multiple answers for the date, then this will remove all, instead of 1.
    await refToReflectionBoardPrompt(boardId, prompt.id!).update({
      answers: firebase.firestore.FieldValue.arrayRemove(answer.conceptual_date),
      ...additionalUpdate,
    });
  } else {
    // add answer info to prompt
    if (!prompt.answers) {
      // get the latest answer of the prompt and set it.
      // in this function we may be updating answers in the past, so the answer object may not be the latest answer.
      const latestAnswerSnap: firebase.firestore.QuerySnapshot<Answer> = await boardLatestAnswerForPromptQuery(boardId, prompt.id!).get();
      let firstDoc = latestAnswerSnap.docs.length > 0 ? latestAnswerSnap.docs[0] : null;

      // if we can't find it, then it doesn't exist. save it as well.
      let additionalUpdate = {
        latest_answer_id: firstDoc?.id ?? null,
        latest_answer_conceptual_date: firstDoc?.data()?.conceptual_date ?? null,
      };

      await refToReflectionBoardPrompt(boardId, prompt.id!).update({
        answers: [answer.conceptual_date],
        ...additionalUpdate,
      });
    } else {
      if (prompt.answers?.find((a => a === conceptualDate))) {
        // found in answers, skip union
        if (!prompt.latest_answer_id || !prompt.latest_answer_conceptual_date || prompt.latest_answer_conceptual_date < conceptualDate) {
          // prompt's latest answer is out of date. find the latest answer
          const latestAnswerSnap: firebase.firestore.QuerySnapshot<Answer> = await boardLatestAnswerForPromptQuery(boardId, prompt.id!).get();
          let firstDoc = latestAnswerSnap.docs.length > 0 ? latestAnswerSnap.docs[0] : null;

          // if we can't find it, then it doesn't exist. save it as well.
          let additionalUpdate = {
            latest_answer_id: firstDoc?.id ?? null,
            latest_answer_conceptual_date: firstDoc?.data()?.conceptual_date ?? null,
          };

          // if value changed, save. otherwise, nothing to do.
          if (prompt.latest_answer_id !== additionalUpdate.latest_answer_id || prompt.latest_answer_conceptual_date !== additionalUpdate.latest_answer_conceptual_date) {
            await refToReflectionBoardPrompt(boardId, prompt.id!).update(additionalUpdate);
          }
        }
      } else {
        // get the latest answer of the prompt and set it.
        // in this function we may be updating answers in the past, so the answer object may not be the latest answer.
        const latestAnswerSnap: firebase.firestore.QuerySnapshot<Answer> = await boardLatestAnswerForPromptQuery(boardId, prompt.id!).get();
        let firstDoc = latestAnswerSnap.docs.length > 0 ? latestAnswerSnap.docs[0] : null;

        // if we can't find it, then it doesn't exist. save it as well.
        let additionalUpdate = {
          latest_answer_id: firstDoc?.id ?? null,
          latest_answer_conceptual_date: firstDoc?.data()?.conceptual_date ?? null,
        };

        await refToReflectionBoardPrompt(boardId, prompt.id!).update({
          answers: firebase.firestore.FieldValue.arrayUnion(answer.conceptual_date),
          ...additionalUpdate,
        });
      }
    }
  }

  const now = new Date();
  // local version
  answer.updated_at = {
    seconds: now.valueOf() / 1000,
    nanoseconds: now.valueOf() * 1000000 % 1000000,
  } as Timestamp;
  dispatch(updateAnswer(answer));

  if (answerId) {
    try {
      // version created by user
      const mutableAnswer = cloneAnswer(answer);
      mutableAnswer.uid = uid;
      await refToReflectionBoardAnswerVersions(boardId, answerId).add(mutableAnswer);
    } catch (error) {
      logError(`Error saving a version ${error}`);
    }
  }
};

export const selectAnswers = (state: RootState) => state.answers;

export default answerSlice.reducer;

