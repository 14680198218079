import React, {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { navigate, RouteComponentProps } from "@reach/router";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import firebase from "firebase/app";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DragIndicator from "@material-ui/icons/DragIndicator";
import AddIcon from "@material-ui/icons/Add";
import Trash from "@material-ui/icons/Delete";
import { Icon } from "@iconify/react";
import { CirclePicker, ColorResult } from "react-color";
import {
  BrightnessController,
  BrightnessControllerConsumer,
  cloneColumns,
  clonePrompt,
  clonePrompts,
  colors,
  getColor,
  UserConsumer,
} from "./Utils";
import { Column, Prompt, PromptLifecycle } from "./Models";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBoard,
  deletePrompt,
  saveColumns,
  savePrompt,
  selectReflections,
  updateBoard,
  updateColumnsAndPrompts,
} from "./store/ReflectionReducer";
import TextareaAutosize from "react-textarea-autosize";
import "./Edit.sass";
import { debounceTime, Subject } from "rxjs";
import DeleteBoardDialog from "./components/ConfirmDeleteDialog";
import { IconButton } from "@material-ui/core";
import WbSunny from "@material-ui/icons/WbSunny";
import SubscriptionDialog from "./Subscription";
import { isPro, selectPlan } from "./store/PlanReducer";

const EmptyColumnName = "Untitled";
const EmptyBoardName = "My new board";
const EmptyPromptQuestion = "Write about something";
const UnusedColumnID = "UNUSED";

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: string[],
  destination: string[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
): { newSource: string[]; newDest: string[] } => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {
    newSource: sourceClone,
    newDest: destClone,
  };

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): CSSProperties | undefined => ({
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "rgba(0, 0, 0, 0.1)" : "transparent",
  ...draggableStyle,
});

function EditPromptView({
  prompt,
  index,
  changePromptQuestion,
  changePromptLifecycle,
  deletePrompt,
  color,
}: {
  prompt: Prompt;
  index: number;
  changePromptQuestion: (promptId: string, newQuestion: string) => void;
  changePromptLifecycle?: (
    promptId: string,
    newLifecycle: PromptLifecycle
  ) => void;
  deletePrompt?: (promptId: string) => void;
  color?: string;
}) {
  const dispatch = useDispatch();
  const [onChange$, setOnChange] = useState<Subject<string>>();
  const [autoRenew, setAutoRenew] = useState<boolean>(
    prompt?.lifecycle !== PromptLifecycle.Manual ?? true
  );

  useEffect(() => {
    if (!onChange$) {
      setOnChange(new Subject<string>());
      return;
    }

    const changeSubscription = onChange$
      .pipe(debounceTime(1000))
      .subscribe((text) => {
        changePromptQuestion(prompt.id!, text);
      });

    return () => {
      changeSubscription.unsubscribe();
    };
  }, [changePromptQuestion, dispatch, onChange$, prompt]);

  function onPromptChange(
    event: ChangeEvent<HTMLTextAreaElement>,
    promptId: string
  ) {
    let value = event.currentTarget.value;
    if (value.trim() === "") {
      value = EmptyPromptQuestion;
    }
    onChange$?.next(value);
  }

  function onPromptEnter(
    event: KeyboardEvent<HTMLTextAreaElement>,
    promptId: string
  ) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      let value = event.currentTarget.value;
      if (value.trim() === "") {
        value = EmptyPromptQuestion;
      }
      event.currentTarget.blur();
      event.preventDefault();
      event.currentTarget.value = value;
      onChange$?.next(value);
    }
  }

  function toggleAutoRenew() {
    setAutoRenew(!autoRenew);
    if (prompt.id && changePromptLifecycle) {
      // if was manual, change to daily. otherwise, change to manual.
      changePromptLifecycle(
        prompt.id,
        prompt.lifecycle === PromptLifecycle.Manual
          ? PromptLifecycle.Daily
          : PromptLifecycle.Manual
      );
    }
  }

  return (
    <>
      {prompt?.id && (
        <Draggable draggableId={prompt.id} index={index}>
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <div
              className="Prompt"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <DragIndicator className="DragPrompt" />
              <TextareaAutosize
                minRows={1}
                onChange={(e) => onPromptChange(e, prompt.id!)}
                onKeyDown={(e) => onPromptEnter(e, prompt.id!)}
                defaultValue={prompt.question}
                style={{ color: color ?? "inherit" }}
              />
              {deletePrompt && (prompt.answers?.length ?? 0) > 0 ? (
                <div className="Counts">{prompt.answers?.length}</div>
              ) : (
                deletePrompt && (
                  <div
                    className="Prompt-Remove"
                    onClick={(e) => deletePrompt(prompt.id!)}
                  >
                    <Icon icon="ion:remove-circle" />
                  </div>
                )
              )}
              {changePromptLifecycle && (
                <IconButton
                  aria-label="sticky"
                  size="small"
                  className="ToggleAutoRenew"
                  onClick={toggleAutoRenew}
                >
                  {autoRenew ? (
                    <Icon className="Off" icon="ic:baseline-pin-off" />
                  ) : (
                    <Icon icon="carbon:pin-filled" />
                  )}
                </IconButton>
              )}
            </div>
          )}
        </Draggable>
      )}
    </>
  );
}

function EditColumnView({
  column,
  prompts,
  openColorPicker,
  changeColumnName,
  changePromptQuestion,
  addPromptQuestion,
  changePromptLifecycle,
  deleteColumn,
  moveColumnLeft,
  moveColumnRight,
  dark,
}: {
  column: Column;
  prompts: { [key: string]: Prompt };
  dark?: boolean | null;
  openColorPicker: (e: MouseEvent<HTMLDivElement>, column: string) => void;
  changeColumnName: (columnId: string, newName: string) => void;
  changePromptQuestion: (promptId: string, newQuestion: string) => void;
  addPromptQuestion: (columnId: string, newQuestion: string) => void;
  changePromptLifecycle: (
    promptId: string,
    newLifecycle: PromptLifecycle
  ) => void;
  deleteColumn: (columnId: string) => void;
  moveColumnLeft: (columnId: string) => void;
  moveColumnRight: (columnId: string) => void;
}) {
  function onColumnNameChange(event: ChangeEvent<HTMLTextAreaElement>) {
    let value = event.currentTarget.value;
    if (value.trim() === "") {
      value = EmptyColumnName;
    }
    changeColumnName(column.id!, value);
  }

  function onColumnNameEnter(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      let value = event.currentTarget.value;
      if (value.trim() === "") {
        value = EmptyColumnName;
      }
      event.currentTarget.blur();
      event.preventDefault();
      event.currentTarget.value = value;
      changeColumnName(column.id!, value);
      return false;
    }
  }

  function onAddNewPromot(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      const value = event.currentTarget.value;
      event.currentTarget.blur();
      event.currentTarget.value = "";
      event.preventDefault();
      if (value.trim() === "") {
        return false;
      }
      addPromptQuestion(column.id!, value);
      return false;
    }
  }

  const ColumnPromptLimitReached = (column.prompts?.length ?? 0) >= 10;

  return (
    <div className="Column">
      <div className="Column-Actions">
        <div className="Column-Move">
          <div onClick={(e) => moveColumnLeft(column.id!)}>
            <Icon icon="iconoir:nav-arrow-left" />
          </div>
          <div onClick={(e) => moveColumnRight(column.id!)}>
            <Icon icon="iconoir:nav-arrow-right" />
          </div>
        </div>
        <div
          className="Column-Color"
          onClick={(e) => openColorPicker(e, column.id!)}
        >
          <Icon icon="iconoir:color-picker-empty" />
        </div>
        <div
          className="Column-Remove"
          onClick={(e) => deleteColumn(column.id!)}
        >
          <Icon icon="carbon:delete" />
        </div>
      </div>
      <div className="Column-Settings">
        <TextareaAutosize
          minRows={1}
          defaultValue={column.name}
          onChange={onColumnNameChange}
          onKeyDown={onColumnNameEnter}
          style={{ color: getColor(dark, column.color) ?? "inherit" }}
        />
      </div>
      <Droppable
        droppableId={column.id!}
        isDropDisabled={ColumnPromptLimitReached}
      >
        {(provided) => (
          <div ref={provided.innerRef} className="Prompts">
            {column.prompts
              ?.filter((p) => Boolean(prompts[p]))
              ?.map(
                (prompt, index) =>
                  prompts[prompt] && (
                    <EditPromptView
                      key={prompt}
                      prompt={prompts[prompt]}
                      index={index}
                      changePromptQuestion={changePromptQuestion}
                      color={getColor(dark, column.color) ?? "inherit"}
                      changePromptLifecycle={changePromptLifecycle}
                    />
                  )
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!ColumnPromptLimitReached && (
        <div className="Column-NewPrompt">
          <TextareaAutosize
            minRows={1}
            maxRows={1}
            onKeyDown={onAddNewPromot}
            defaultValue={""}
            placeholder="Add a new prompt"
          />
        </div>
      )}
      {ColumnPromptLimitReached && (
        <div className="LimitNotice">
          Each list can have a maximum of 10 prompts.
        </div>
      )}
    </div>
  );
}

interface EditState {
  columns: Column[];
  prompts: { [key: string]: Prompt };
}

function Edit(props: {
  user?: firebase.User | null;
  boardId?: string | null;
  controller: BrightnessController | null;
}) {
  const dispatch = useDispatch();
  const { reflection, board, boardId, prompts } =
    useSelector(selectReflections);
  const plan = useSelector(selectPlan);

  const [editState, setEditState] = useState<EditState>({
    columns: [],
    prompts: {},
  } as EditState);
  const [unusedColumn, setUnusedColumn] = useState<Column>({
    prompts: [],
    id: UnusedColumnID,
  } as Column);

  const [pickerEl, setPickerEl] = useState<HTMLDivElement | null>(null);
  const [pickerColumn, setPickerColumn] = useState<string | null>(null);
  const [deletingColumn, setDeletingColumn] = useState<string | null>(null);

  const [openDeleteBoardModal, setOpenDeleteBoardModal] =
    useState<boolean>(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    useState<boolean>(false);

  const subscribed = isPro(plan);

  useEffect(() => {
    const newColumns = cloneColumns(board?.columns ?? []);
    const newPrompts = clonePrompts(prompts ?? {});
    const newUsedPrompts = new Set<string>(
      newColumns.flatMap((c) => c.prompts?.map((p) => p) ?? []) ?? []
    );
    const newUnusedPrompts = {
      prompts: Object.values(newPrompts)
        .map((p) => p.id!)
        .filter((p) => !newUsedPrompts.has(p)),
      id: UnusedColumnID,
    } as Column;

    setEditState({ columns: newColumns, prompts: newPrompts } as EditState);
    setUnusedColumn(newUnusedPrompts);
    // console.log(editState);
  }, [board?.columns, prompts]);

  useEffect(() => {
    // force board
    const requestedBoardId = props.boardId ?? reflection?.defaultBoardId;
    if (requestedBoardId && requestedBoardId !== boardId) {
      dispatch(changeBoard(requestedBoardId));
      navigate(`/app/${requestedBoardId}/edit`);
    }
  }, [boardId, dispatch, props.boardId, reflection?.defaultBoardId]);

  function onSaveColumns(columns: Column[]) {
    setEditState({ columns, prompts: editState.prompts } as EditState);
    if (board?.id) {
      dispatch(saveColumns(board?.id, columns));
    }
  }

  function onSaveBoardName(newName: string) {
    if (board?.id) {
      dispatch(updateBoard(board?.id, newName));
    }
  }

  function onDone(event: MouseEvent<HTMLElement>) {
    navigate(`/app/${boardId}`);
  }

  function canAddMore(columns: Column[]): boolean {
    const prompts = columns.reduce((sum, c) => {
      if (c.id === UnusedColumnID) {
        return sum;
      }
      return sum + (c.prompts?.length ?? 0);
    }, 0);

    if (!subscribed && prompts >= 16) {
      return false;
    }

    return true;
  }

  function getColumn(id: string): Column | null | undefined {
    if (id === UnusedColumnID) {
      return unusedColumn;
    }
    return editState.columns.find((c) => c.id === id);
  }

  function openColorPicker(e: MouseEvent<HTMLDivElement>, column: string) {
    setPickerColumn(column);
    setPickerEl(e.currentTarget);
  }

  function handleCloseColorPicker() {
    setPickerEl(null);
    setPickerColumn(null);
  }

  function openDeleteColumnConfirm(column: string) {
    setDeletingColumn(column);
  }

  function handleConfirmDeleteColumn() {
    if (deletingColumn) {
      removeColumn(deletingColumn);
    }
    setDeletingColumn(null);
  }

  function handleCancelDeleteColumn() {
    setDeletingColumn(null);
  }

  const open = Boolean(pickerEl);
  const id = open ? "color-picker" : undefined;

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const column = getColumn(source.droppableId);
      if (column && column.prompts) {
        const prompts = reorder(
          column.prompts,
          source.index,
          destination.index
        );
        column.prompts = prompts;
        onSaveColumns([...editState.columns]);
      }
    } else {
      // cannot move prompts to active columns if limit reached.
      if (
        source.droppableId === UnusedColumnID &&
        destination.droppableId !== UnusedColumnID &&
        !canAddMore(editState.columns)
      ) {
        setOpenSubscriptionModal(true);
        return;
      }

      const srcColumn = getColumn(source.droppableId);
      const destColumn = getColumn(destination.droppableId);
      if (srcColumn && srcColumn.prompts && destColumn && destColumn.prompts) {
        const result = move(
          srcColumn.prompts,
          destColumn.prompts,
          source,
          destination
        );
        srcColumn.prompts = result.newSource;
        destColumn.prompts = result.newDest;
        onSaveColumns([...editState.columns]);
      }
    }
  }

  function onColorChange(
    color: ColorResult,
    event: ChangeEvent<HTMLInputElement>
  ) {
    const column = editState.columns.find((c) => c.id === pickerColumn);
    if (column) {
      column.color = color.hex;
    }
    const newColumns = [...editState.columns];
    onSaveColumns(newColumns);
  }

  function onColumnNameChanged(columnId: string, newName: string) {
    if (newName.trim() === "") {
      return;
    }
    const column = editState.columns.find((c) => c.id === columnId);
    if (column) {
      column.name = newName;
    }
    onSaveColumns(editState.columns);
  }

  function onPromptChanged(promptId: string, newQuestion: string) {
    if (newQuestion.trim() === "") {
      return;
    }

    let prompt = editState.prompts[promptId];
    if (prompt) {
      prompt = clonePrompt(prompt);
      prompt.question = newQuestion;
    }

    if (board?.id) {
      dispatch(savePrompt(board?.id, prompt));
    }
    setEditState({ columns: editState.columns, prompts } as EditState);
  }

  function onPromptLifecycleChanged(
    promptId: string,
    newLifecycle: PromptLifecycle
  ) {
    let prompt = editState.prompts[promptId];
    if (prompt) {
      prompt = clonePrompt(prompt);
      prompt.lifecycle = newLifecycle;
    }

    if (board?.id) {
      dispatch(savePrompt(board?.id, prompt));
    }
    setEditState({ columns: editState.columns, prompts } as EditState);
  }

  function onPromptDelete(promptId: string) {
    const prompts = { ...editState.prompts };
    delete prompts[promptId];
    setEditState({ columns: editState.columns, prompts } as EditState);
    if (board?.id) {
      dispatch(deletePrompt(board?.id, promptId));
    }
  }

  function onAddPromptQuestion(columnId: string, newQuestion: string) {
    if (newQuestion.trim() === "") {
      return;
    }

    if (!canAddMore(editState.columns)) {
      setOpenSubscriptionModal(true);
      return;
    }

    const newPrompt = { question: newQuestion, id: uuid() };
    editState.prompts[newPrompt.id] = newPrompt;

    const column = editState.columns.find((c) => c.id === columnId);
    if (column) {
      column.prompts = [...(column.prompts ?? []), newPrompt.id];
    }

    const newColumns = [...editState.columns];

    // patch local store
    setEditState({
      columns: newColumns,
      prompts: editState.prompts,
    } as EditState);

    // patch redux store
    dispatch(
      updateColumnsAndPrompts({
        columns: newColumns,
        prompts: editState.prompts,
      })
    );

    // update
    if (board?.id) {
      dispatch(saveColumns(board?.id, editState.columns));
      dispatch(savePrompt(board?.id, newPrompt));
    }
  }

  function addColumn() {
    const newColumns = [
      ...editState.columns,
      {
        id: uuid(),
        name: EmptyColumnName,
        color: "#847FFF",
        prompts: [],
      } as Column,
    ];
    // create a new object to force refresh
    onSaveColumns(newColumns);
  }

  function removeColumn(columnId: string) {
    const newColumns = editState.columns.filter(
      (column) => column.id !== columnId
    );
    onSaveColumns(newColumns);
  }

  function moveColumnLeft(columnId: string) {
    const index = editState.columns.findIndex((c) => c.id === columnId);
    if (index > 0) {
      const newColumns = [...editState.columns];
      const replace = editState.columns[index - 1];
      newColumns[index - 1] = newColumns[index];
      newColumns[index] = replace;
      onSaveColumns(newColumns);
    }
  }

  function moveColumnRight(columnId: string) {
    const index = editState.columns.findIndex((c) => c.id === columnId);
    if (index < editState.columns.length - 1) {
      const newColumns = [...editState.columns];
      const replace = editState.columns[index + 1];
      newColumns[index + 1] = newColumns[index];
      newColumns[index] = replace;
      onSaveColumns(newColumns);
    }
  }

  function onBoardNameChange(event: ChangeEvent<HTMLTextAreaElement>) {
    let value = event.currentTarget.value;
    if (value.trim() === "") {
      value = EmptyBoardName;
    }
    changeBoardName(value);
  }

  function onBoardNameEnter(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      let value = event.currentTarget.value;
      if (value.trim() === "") {
        value = EmptyBoardName;
      }
      event.currentTarget.blur();
      event.preventDefault();
      event.currentTarget.value = value;
      changeBoardName(value);
      return false;
    }
  }

  function changeBoardName(newName: string) {
    if (newName.trim() === "") {
      return;
    }

    onSaveBoardName(newName);
  }

  function onToggleBrightness() {
    props.controller?.setDark(!(props.controller?.dark ?? false));
  }

  return (
    <div
      className={`CanvasWrapper ${
        props.controller?.dark ?? false ? "Dark" : ""
      }`}
    >
      <div className="CanvasMenu">
        <img
          className="logo"
          src={`${process.env.PUBLIC_URL}/${
            props.controller?.dark ? "logo-dark.svg" : "logo.svg"
          }`}
          alt="Jot"
        />
        <div onClick={onToggleBrightness}>
          <IconButton aria-label="brightness" size="small">
            {props.controller?.dark ?? true ? (
              <Icon icon="akar-icons:moon-fill" />
            ) : (
              <WbSunny />
            )}
          </IconButton>
        </div>
        <div className="BoardControls">
          {board && (
            <TextareaAutosize
              minRows={1}
              defaultValue={board.name ?? EmptyBoardName}
              onChange={onBoardNameChange}
              onKeyDown={onBoardNameEnter}
            />
          )}
          {boardId && (
            <div
              className="RemoveBoard"
              onClick={() => setOpenDeleteBoardModal(true)}
            >
              <Trash />
            </div>
          )}
        </div>
        <div className="CanvasControls">
          <Button
            aria-label="exit"
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            onClick={onDone}
          >
            Done
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="Edit">
          <div className="Unused-Column">
            <h3>Unused prompts</h3>
            <Droppable droppableId={UnusedColumnID}>
              {(provided) => (
                <div ref={provided.innerRef} className="Unused-Prompts">
                  {unusedColumn.prompts
                    ?.filter((p) => Boolean(editState.prompts[p]))
                    ?.map((prompt, index) => (
                      <EditPromptView
                        key={prompt}
                        prompt={editState.prompts[prompt]}
                        index={index}
                        changePromptQuestion={onPromptChanged}
                        deletePrompt={onPromptDelete}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          {editState.columns.map((c) => (
            <EditColumnView
              key={c.id}
              column={c}
              dark={props.controller?.dark}
              prompts={editState.prompts}
              openColorPicker={openColorPicker}
              changeColumnName={onColumnNameChanged}
              changePromptQuestion={onPromptChanged}
              changePromptLifecycle={onPromptLifecycleChanged}
              addPromptQuestion={onAddPromptQuestion}
              deleteColumn={openDeleteColumnConfirm}
              moveColumnLeft={moveColumnLeft}
              moveColumnRight={moveColumnRight}
            />
          ))}
          <Button className="AddColumn" onClick={addColumn}>
            <AddIcon />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={pickerEl}
            onClose={handleCloseColorPicker}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div className="ColorPicker">
              <CirclePicker
                colors={
                  props.controller?.dark ?? false
                    ? colors.map((c) => c[1])
                    : colors.map((c) => c[0])
                }
                onChange={onColorChange}
              />
            </div>
          </Popover>
        </div>
      </DragDropContext>
      <Dialog
        open={Boolean(deletingColumn)}
        onClose={handleCancelDeleteColumn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Delete column?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The prompts will not be deleted. They'll show up in the "Unused
            prompts" list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteColumn}>Don't delete</Button>
          <Button onClick={handleConfirmDeleteColumn} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {props.user && (
        <SubscriptionDialog
          open={openSubscriptionModal}
          uid={props.user?.uid}
          onClose={() => setOpenSubscriptionModal(false)}
        />
      )}
      {board?.id && props.user && (
        <DeleteBoardDialog
          uid={props.user?.uid}
          boardId={board?.id}
          boardName={board?.name ?? "Untitled board"}
          open={openDeleteBoardModal}
          onClose={() => setOpenDeleteBoardModal(false)}
        />
      )}
    </div>
  );
}

function EditWithUser({
  board,
}: { board?: string | null } & RouteComponentProps) {
  return (
    <UserConsumer>
      {(user) => {
        return (
          <BrightnessControllerConsumer>
            {(controller) => {
              return (
                <Edit user={user} boardId={board} controller={controller} />
              );
            }}
          </BrightnessControllerConsumer>
        );
      }}
    </UserConsumer>
  );
}

export default EditWithUser;
