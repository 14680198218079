import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { selectPlan } from "./store/PlanReducer";
import { useSelector } from "react-redux";
import { refToCheckoutSessions } from "./db";
import "./Subscription.sass";

export default function SubscriptionDialog(props: { open: boolean; uid: string; onClose: () => void }) {
  const [creatingSession, setCreatingSession] = useState<boolean>();
  const { products } = useSelector(selectPlan);

  const handleListItemClick = async (price: string, oneTime: boolean) => {
    setCreatingSession(true);

    let checkoutSession = {};

    // For one time payments set mode to payment.
    if (oneTime) {
      checkoutSession = {
        price: price,
        success_url: window.location.toString(),
        return_url: window.location.toString(),
        cancel_url: window.location.toString(),
        mode: "payment",
        payment_method_types: ["card"],
      };
    } else {
      checkoutSession = {
        automatic_tax: true,
        tax_id_collection: true,
        price: price,
        success_url: window.location.toString(),
        return_url: window.location.toString(),
        cancel_url: window.location.toString(),
      };
    }

    // initiate
    const docRef = await refToCheckoutSessions(props.uid).add(checkoutSession);

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      if (!props.open) {
        return;
      }
      const data = snap.data();
      if (data?.error) {
        setCreatingSession(false);
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${data.error.message}`);
      }
      if (data?.url) {
        setCreatingSession(false);
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(data.url);
      }
    });
  };

  return (
    <Dialog
      className="Upgrade-Dialog"
      onClose={props.onClose}
      aria-labelledby="Upgrade-Dialog-Title"
      open={props.open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="Upgrade-Dialog-Title">Upgrade</DialogTitle>
      <ul>
        <li className="Feature">Infinite boards</li>
        <li className="Feature">Infinite prompts</li>
        <li className="Feature">Calendar view</li>
        <li className="Feature">Auto word count</li>
        <li className="Feature">Up to 2 members</li>
        <li className="Feature">Premium templates</li>
        <li className="Feature">Priority support</li>
        <li className="Feature">Cancel anytime</li>
      </ul>
      <List>
        {!creatingSession &&
          products.map((product) =>
            Object.keys(product.prices ?? {}).map((priceId) => {
              const price = (product.prices ?? {})[priceId];
              const interval = price?.interval ? `- a ${price.interval}` : "- lifetime";
              return (
                <ListItem button onClick={() => handleListItemClick(priceId, !price?.interval)} key={priceId}>
                  <ListItemText primary={`${product.name} ${interval}`} />
                  <ListItemSecondaryAction>
                    <ListItemText
                      primary={`$${(price?.unit_amount ?? 10000) / 100} ${price?.currency} / ${
                        price?.interval ?? "lifetime"
                      }`}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          )}
        {creatingSession && <div className="Creating">One sec...</div>}
      </List>
    </Dialog>
  );
}
