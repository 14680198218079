import React, { useMemo, useState } from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { useMediaQuery } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./index.sass";
import App from "./App";
import { Router } from "@reach/router";
import SignIn from "./components/SignIn";
import { RouteNames } from "./RouteNames";
import { store } from "./store/Store";
import Canvas from "./Canvas";
import Edit from "./Edit";
import Journal from "./Journal";
import { BrightnessController, BrightnessControllerProvider } from "./Utils";
import Members from "./Members";
import Invite from "./Invite";
import Marketing from "./Marketing";
import Settings from "./Settings";

declare global {
  interface Window {
    app: any;
  }
}

function Root() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [dark, setDark] = useState<boolean | null>();
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: dark ?? prefersDarkMode ? "dark" : "light",
          primary: {
            main: dark ?? prefersDarkMode ? "#60828d" : "#8a7e9f",
          },
        },
        props: {
          MuiButtonBase: {
            disableRipple: true,
          },
        },
        typography: {
          fontFamily: [
            "proxima-nova",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            "Oxygen-Sans",
            "Ubuntu",
            "Cantarell",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
          fontWeightRegular: 400,
        },
      }),
    [dark, prefersDarkMode]
  );

  return (
    <React.StrictMode>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <BrightnessControllerProvider value={{ dark: dark ?? prefersDarkMode, setDark } as BrightnessController}>
              <CssBaseline />
              <Router>
                <App path={RouteNames.Home}>
                  <Canvas key="main" path="/" />
                  <Canvas key="board" path="/:board" />
                  <Canvas key="history" path="/:board/history/:date" />
                  <Journal path="/:board/journal/b/:columnId" />
                  <Journal path="/:board/journal/p/:promptId" />
                  <Journal path="/:board/journal" />
                  <Edit path={RouteNames.Edit} />
                  <Edit path="/:board/edit" />
                  <Members path="/:board/members" />
                  <Invite path="/:board/invite/:invite/:code" />
                  <Settings path="/settings" />
                </App>
                <Marketing path={RouteNames.Marketing} />
                <SignIn path={RouteNames.SignIn} />
                {/* <Templates path={RouteNames.Templates} /> */}
              </Router>
            </BrightnessControllerProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </React.StrictMode>
  );
}

async function bootstrapApplication() {
  render(<Root />, document.getElementById("root"));
}

bootstrapApplication();
