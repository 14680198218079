import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Prompt } from "./Models";
import { selectAnswers } from "./store/AnswerReducer";
import { selectReflections } from "./store/ReflectionReducer";
import "./Progress.sass";

export default function ProgressView(props: { onComplete?: () => void }) {
  const answers = useSelector(selectAnswers);
  const { board, prompts } = useSelector(selectReflections);
  const previousProgress = useRef<number>(100);

  const onBoardPrompts: Prompt[] =
    board?.columns?.flatMap((c) => c.prompts?.map((p) => (prompts ?? {})[p]) ?? []) ?? [];
  const completedPrompts = onBoardPrompts.filter(
    (p) =>
      p &&
      answers.answersByPromptId[p.id!] &&
      answers.answersByPromptId[p.id!]?.find((a) => (a.text?.trim()?.length ?? 0) > 0)
  );

  const percentage = onBoardPrompts.length === 0 ? -1 : (completedPrompts.length * 100) / onBoardPrompts.length;

  useEffect(() => {
    if (previousProgress.current < 100 && percentage >= 100 && props.onComplete) {
      props.onComplete();
    }

    if (percentage >= 0) {
      previousProgress.current = percentage;
    }
  }, [percentage, props]);

  return (
    <div className="ProgressWrapper">
      <div className="Progress">
        <div className="Bar" style={{ width: `${percentage}%` }}></div>
      </div>
      <div className="ProgressLabel">
        {percentage === 0
          ? "It's a fresh new day!"
          : percentage === 100
          ? "🎉"
          : `${completedPrompts.length} / ${onBoardPrompts.length}`}
      </div>
    </div>
  );
}
