import React, { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import firebase from "firebase/app";
import "date-fns";
import { combineLatest } from "rxjs";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import BookIcon from "@material-ui/icons/Book";
import WbSunny from "@material-ui/icons/WbSunny";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  BrightnessController,
  BrightnessControllerConsumer,
  formatConceptualDate,
  getColor,
  parseConceptualDate,
  UserConsumer,
} from "./Utils";
import { Column, Prompt, PromptLifecycle } from "./Models";
import "./Canvas.sass";
import PromptView, { DirtyPromptValue, FetchingPromptView } from "./Prompt";
import { useDispatch, useSelector } from "react-redux";
import { changeConceptualDate, saveAnswer, selectAnswers, updateAnswers } from "./store/AnswerReducer";
import { changeBoard, selectReflections } from "./store/ReflectionReducer";
import {
  boardAnswersStreamByDate,
  boardLatestAnswersForManualPromptsStream,
  boardLatestAnswersForWeeklyAndMonthlyPromptsStream,
} from "./db";
import { isPro, selectPlan } from "./store/PlanReducer";
import SubscriptionDialog from "./Subscription";
import ProgressView from "./Progress";
import BoardSelector, { NEW_BOARD_VALUE } from "./components/BoardSelector";
import NewBoardDialog from "./components/NewBoardDialog";
import ScrollShadow from "./components/ScrollShadow";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Confetti from "./components/Confetti";
import BoardSettings from "./components/BoardSettings";
import { Icon } from "@iconify/react";

function FetchingColumnView({ column, dark }: { column: Column; dark: boolean }) {
  return (
    <div className="Column">
      <div className="ColumnTitle">
        <h2 style={{ backgroundColor: getColor(dark, column?.color) ?? "black" }}>{column.name}</h2>
        <div style={{ background: getColor(dark, column?.color) ?? "black" }} className="Line"></div>
      </div>
      <div className="Prompts">
        {column?.prompts?.map((p) => {
          return <FetchingPromptView key={`fetching/${p}`} />;
        })}
      </div>
    </div>
  );
}

function ColumnView({
  column,
  conceptualDate,
  setFullscreenPrompt,
  fullscreenPrompt,
  prompts,
  dark,
  updateDirtyValue,
  boardId,
  uid,
}: {
  column: Column;
  conceptualDate: string;
  fullscreenPrompt?: string | null;
  boardId?: string | null;
  setFullscreenPrompt: (promptId: string) => void;
  prompts?: { [key: string]: Prompt } | null;
  dark?: boolean;
  updateDirtyValue: (dirtyValue: DirtyPromptValue | null) => void;
  uid?: string | null;
}) {
  return (
    <UserConsumer>
      {(_) => {
        return (
          <div className="Column">
            <div className="ColumnTitle">
              <h2 style={{ backgroundColor: getColor(dark, column?.color, 0.8) ?? "black" }}>{column.name}</h2>
              <div style={{ background: getColor(dark, column?.color) ?? "black" }} className="Line"></div>
            </div>
            <ScrollShadow dark={dark ?? false}>
              <div className="Prompts">
                {column?.prompts
                  // ?.filter((p) => p !== fullscreenPrompt)
                  ?.map((p) => {
                    const prompt = (prompts ?? {})[p];
                    return (
                      prompt && (
                        <PromptView
                          key={`${conceptualDate}/${prompt?.id}`}
                          boardId={boardId!}
                          prompt={prompt}
                          conceptualDate={conceptualDate}
                          setFullscreenPrompt={setFullscreenPrompt}
                          isFullscreen={false}
                          bgColor={column.color}
                          dark={dark}
                          updateDirtyValue={updateDirtyValue}
                          uid={uid}
                        />
                      )
                    );
                  })}
              </div>
            </ScrollShadow>
          </div>
        );
      }}
    </UserConsumer>
  );
}

function Canvas(
  props: {
    date?: string | null;
    user?: firebase.User | null;
    boardId?: string | null;
    controller: BrightnessController | null;
  } & RouteComponentProps
) {
  const { conceptualDate, fetching } = useSelector(selectAnswers);
  const { reflection, board, boards, boardId, prompts } = useSelector(selectReflections);
  // const { boards } = useSelector(selectReflections);
  const plan = useSelector(selectPlan);
  const dispatch = useDispatch();
  const today = formatConceptualDate(new Date());
  const [fullscreenPrompt, setFullscreenPrompt] = useState<string | null>();
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
  const [openNewBoardModal, setOpenNewBoardModal] = useState<boolean>(false);
  const [isCelebrating, setIsCelebrating] = useState<boolean>(false);

  const subscribed = isPro(plan);

  const dirtyValue = useRef<DirtyPromptValue | null>(null);

  const handleDateChange = (date: Date | null) => {
    if (date && formatConceptualDate(date) !== conceptualDate) {
      const newConceptualDate = formatConceptualDate(date);
      if (today === newConceptualDate) {
        navigate(`/app/${props.boardId}`);
      } else {
        navigate(`/app/${props.boardId}/history/${formatConceptualDate(date)}`);
      }
      dispatch(changeConceptualDate(formatConceptualDate(date)));
    }
  };

  function fullscreenPromptColor(fullscreenPrompt: string): string | undefined {
    return board?.columns?.find((c) => c.prompts?.find((p) => p === fullscreenPrompt))?.color;
  }

  function onJournal(_: MouseEvent<HTMLElement>) {
    navigate(`/app/${props.boardId}/journal`);
  }

  function onToday(_: MouseEvent<HTMLElement>) {
    navigate(`/app/${props.boardId}`);
  }

  function canCreateMore(): boolean {
    if (!subscribed && (boards?.length ?? 0) >= 3) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    // force today
    let newDate: string | undefined | null;
    if (!props.date && conceptualDate !== today) {
      dispatch(changeConceptualDate(today));
      newDate = today;
    } else if (props.date && conceptualDate !== props.date) {
      // force url date
      dispatch(changeConceptualDate(props.date));
      newDate = props.date;
    }

    // force board
    const requestedBoardId = props.boardId ?? reflection?.defaultBoardId;
    if (requestedBoardId && requestedBoardId !== boardId) {
      dispatch(changeBoard(requestedBoardId));
      if (newDate && newDate !== today) {
        navigate(`/app/${requestedBoardId}/history/${newDate}`);
      } else {
        navigate(`/app/${requestedBoardId}`);
      }
    } else if (!props.boardId && reflection?.defaultBoardId) {
      dispatch(changeBoard(reflection?.defaultBoardId));
      navigate(`/app/${reflection?.defaultBoardId}`);
    }
  }, [boardId, conceptualDate, dispatch, props.boardId, props.date, reflection?.defaultBoardId, today]);

  function toggleFullscreen(promptId: string) {
    // save if latestText is not null - meaninging there are pending changes.
    saveDirtyValue();

    if (fullscreenPrompt) {
      // force refresh.
      setFullscreenPrompt(null);
    } else {
      setFullscreenPrompt(promptId);
    }
  }

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    const allDates = new Set<string>([]);
    Object.values(prompts ?? {}).forEach((p) => p.answers?.forEach((a) => allDates.add(a)));
    if (day && allDates.has(formatConceptualDate(day))) {
      return <div className="ActiveDate">{dayComponent}</div>;
    }

    return dayComponent;
  };

  const saveDirtyValue = useCallback(() => {
    if (dirtyValue.current) {
      const prompt = (prompts ?? {})[dirtyValue.current.promptId];
      if (board?.id && prompt && conceptualDate && props.user) {
        dispatch(
          saveAnswer(
            board?.id,
            prompt,
            conceptualDate,
            dirtyValue.current.answerId,
            props.user.uid,
            dirtyValue.current.dirtyValue
          )
        );
      }
    }
    dirtyValue.current = null;
  }, [board?.id, conceptualDate, dispatch, prompts, props.user]);

  const deferredReload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (dirtyValue.current) {
        saveDirtyValue();
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [saveDirtyValue]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", deferredReload);

    return () => window.removeEventListener("beforeunload", deferredReload);
  }, [deferredReload]);

  function onChangeBoard(value: string) {
    if (value === NEW_BOARD_VALUE) {
      if (canCreateMore()) {
        setOpenNewBoardModal(true);
      } else {
        setOpenSubscriptionModal(true);
      }
      return;
    }

    if (value === "") {
      navigate(`/app/${reflection?.defaultBoardId ?? ""}`);
      return;
    }
    navigate(`/app/${value}`);
  }

  function onSettings() {
    navigate(`/app/settings`);
  }

  function onUpgrade() {
    setOpenSubscriptionModal(true);
  }

  function onToggleBrightness() {
    props.controller?.setDark(!(props.controller?.dark ?? false));
  }

  useEffect(() => {
    if (!board?.id) {
      return;
    }

    const streams = [boardAnswersStreamByDate(board.id, conceptualDate ?? today)];

    // weekly & monthly streams
    const weeklyAndMonthlyPrompts: Prompt[] = Object.values(prompts ?? {}).filter(
      (p) =>
        p.id &&
        (p.lifecycle === PromptLifecycle.Weekly ||
          p.lifecycle === PromptLifecycle.Monthly ||
          p.lifecycle === PromptLifecycle.WeeklyBurn ||
          p.lifecycle === PromptLifecycle.DailyBurn)
    );

    if (weeklyAndMonthlyPrompts.length > 0 && conceptualDate) {
      streams.push(
        boardLatestAnswersForWeeklyAndMonthlyPromptsStream(board.id, weeklyAndMonthlyPrompts, conceptualDate)
      );
    }

    // manual streams
    const manualPromptIds: string[] = Object.values(prompts ?? {})
      .filter((p) => p.id && p.lifecycle === PromptLifecycle.Manual)
      .map((p) => p.id) as string[];

    if (manualPromptIds.length > 0) {
      // if wanna show answers before a given date, add conceptual date as filter.
      // streams.push(boardLatestAnswersForManualPromptsStream(board.id, manualPromptIds, conceptualDate ?? today));
      streams.push(boardLatestAnswersForManualPromptsStream(board.id, manualPromptIds));
    }

    const answersStream = combineLatest(streams).subscribe(async (arrays) => {
      const data = arrays.reduce((result, answers) => result.concat(answers), []);
      dispatch(updateAnswers(data));
    });

    return () => {
      answersStream.unsubscribe();
    };
  }, [board?.id, conceptualDate, dispatch, prompts, today]);

  const selectedBoardId = board?.id ?? "";

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={`CanvasWrapper ${props.controller?.dark ?? false ? "Dark" : ""}`}>
        <div className="CanvasMenu">
          <div className="CanvasTitle">
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/${props.controller?.dark ? "logo-dark.svg" : "logo.svg"}`}
              alt="Jot"
            />
            <div onClick={onToggleBrightness}>
              <IconButton aria-label="brightness" size="small">
                {props.controller?.dark ?? true ? <Icon icon="akar-icons:moon-fill" /> : <WbSunny />}
              </IconButton>
            </div>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="MMM dd, yyyy"
              margin="normal"
              id="date-picker-inline"
              value={parseConceptualDate(conceptualDate ?? today)}
              onChange={handleDateChange}
              inputVariant="filled"
              renderDay={renderDay}
            />
            {conceptualDate !== today && (
              <Button
                className="TodayButton"
                aria-label="today"
                size="small"
                variant="contained"
                disableElevation
                onClick={onToday}
              >
                Today
              </Button>
            )}
          </div>
          <div className="CenterControls">
            <ProgressView onComplete={() => setIsCelebrating(true)} />
          </div>
          <div className="CanvasControls">
            {!subscribed && (
              <div>
                <Button
                  aria-label="upgrade"
                  color="primary"
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={onUpgrade}
                >
                  Upgrade
                </Button>
              </div>
            )}
            <div onClick={onJournal}>
              <IconButton aria-label="journal" size="small">
                <BookIcon />
              </IconButton>
            </div>
            {board?.id && <BoardSettings boardId={board?.id} />}
            <BoardSelector
              key={boards?.map((b) => b.id)?.join("-") ?? "board-selector"}
              selectedBoardId={selectedBoardId}
              board={board}
              boards={boards}
              onChangeBoard={onChangeBoard}
              showNew={true}
            />
            <div onClick={onSettings}>
              <IconButton aria-label="journal" size="small">
                <Icon icon="bx:bxs-user-circle" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="Canvas" key={conceptualDate}>
          <div className="Columns">
            {fetching
              ? board?.columns?.map((c) => (
                  <FetchingColumnView key={`fetching/${c.id}`} column={c} dark={props.controller?.dark ?? false} />
                ))
              : board?.columns?.map((c) => (
                  <ColumnView
                    key={c.id}
                    column={c}
                    conceptualDate={conceptualDate ?? today}
                    setFullscreenPrompt={toggleFullscreen}
                    prompts={prompts ?? {}}
                    fullscreenPrompt={fullscreenPrompt}
                    dark={props.controller?.dark ?? false}
                    updateDirtyValue={(val) => (dirtyValue.current = val)}
                    boardId={props.boardId}
                    uid={props.user?.uid}
                  />
                ))}
          </div>
        </div>
        {fullscreenPrompt && props.user && (
          <div className="Fullscreen" onClick={() => toggleFullscreen(fullscreenPrompt)}>
            <PromptView
              key={`fullscreen/${conceptualDate}/${fullscreenPrompt}`}
              boardId={props.boardId!}
              prompt={(prompts ?? {})[fullscreenPrompt]}
              conceptualDate={conceptualDate!}
              setFullscreenPrompt={toggleFullscreen}
              isFullscreen={true}
              dark={props.controller?.dark ?? false}
              bgColor={fullscreenPromptColor(fullscreenPrompt)}
              updateDirtyValue={(val) => (dirtyValue.current = val)}
              uid={props.user?.uid}
            />
          </div>
        )}
        {isCelebrating && <Confetti duration={1000} />}
        {props.user && (
          <SubscriptionDialog
            open={openSubscriptionModal}
            uid={props.user.uid}
            onClose={() => setOpenSubscriptionModal(false)}
          />
        )}
        {props.user && (
          <NewBoardDialog open={openNewBoardModal} user={props.user} onClose={() => setOpenNewBoardModal(false)} />
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}

function CanvasWithUser({ date, board }: { date?: string | null; board?: string | null } & RouteComponentProps) {
  return (
    <UserConsumer>
      {(user) => {
        return (
          <BrightnessControllerConsumer>
            {(controller) => {
              return <Canvas user={user} date={date} boardId={board} controller={controller} />;
            }}
          </BrightnessControllerConsumer>
        );
      }}
    </UserConsumer>
  );
}

export default CanvasWithUser;
