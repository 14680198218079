import React, { ReactNode, useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { authState } from "rxfire/auth";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";

import app, {
  getPrices,
  boardPromptsStream,
  userReflectionsStream,
  userSubscriptionStream,
  boardStream,
  boardsStream,
  unfollowBoard,
  userPaymentStream,
} from "./db";
import { RouteNames } from "./RouteNames";
import { initializeProfile, loadProfile } from "./store/ProfileReducer";
import { logError, UserProvider } from "./Utils";
import {
  createReflections,
  initializeBoard,
  initializeBoards,
  initializePrompts,
  initializeReflections,
  migrateDefaultBoard,
  selectReflections,
} from "./store/ReflectionReducer";
import "./App.sass";
import { initializePayments, initializeSubscriptions, loadProducts } from "./store/PlanReducer";
import { Convert, Prompt } from "./Models";
import { catchError, of } from "rxjs";
import { Button } from "@material-ui/core";

function App(props: { children?: ReactNode[] | ReactNode } & RouteComponentProps) {
  const { reflection, board, boardId } = useSelector(selectReflections);
  const [forbidden, setForbidden] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [user, setUser] = useState<firebase.User | null>(null);
  const dispatch = useDispatch();
  const [uid, setUID] = useState<string | undefined | null>(window.localStorage.getItem("uid"));

  // subscribe to auth change
  useEffect(() => {
    authState(app.auth()).subscribe((u) => {
      if (u) {
        if (!user) {
          setUser(u);
          setUID(u.uid);
          window.localStorage.setItem("uid", u.uid);
          dispatch(
            loadProfile({
              uid: u.uid,
              displayName: u.displayName,
            })
          );
        }
      } else {
        setUID(null);
        setUser(null);
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("board");
        dispatch(initializeProfile(null));
        navigate(RouteNames.SignIn);
      }
    });
  }, [user, dispatch]);

  // load reflection from cache
  useEffect(() => {
    if (!reflection) {
      try {
        const reflectionsJson = window.localStorage.getItem("reflections");
        if (reflectionsJson) {
          const reflectionObject = Convert.toMindfulReflections(reflectionsJson);
          if (reflectionObject) {
            dispatch(initializeReflections(reflectionObject));
          }
        }
      } catch (error) {
        window.localStorage.removeItem("reflections");
        logError("Error reading reflections cache");
      }
    }
  }, [reflection, dispatch]);

  // load user boards
  useEffect(() => {
    if (uid) {
      const reflectionAndPromptSubscription = userReflectionsStream(uid).subscribe(async (reflection) => {
        // console.log(reflection);
        if (!reflection || !reflection.initialized) {
          if (!creating && user) {
            setCreating(true);
            dispatch(createReflections(user));
          }
        } else if (
          reflection &&
          (!reflection.boards || reflection.boards?.length === 0 || !reflection.defaultBoardId)
        ) {
          if (!creating && user) {
            // initialize default board. migrate old to new
            setCreating(true);
            dispatch(migrateDefaultBoard(user));
          }
        } else {
          setCreating(false);
          dispatch(initializeReflections(reflection));
          window.localStorage.setItem("reflections", Convert.mindfulReflectionsToJson(reflection));
        }
      });
      return () => {
        reflectionAndPromptSubscription.unsubscribe();
      };
    }
  }, [creating, dispatch, uid, user]);

  // load board from cache
  useEffect(() => {
    if (!boardId || board) {
      return;
    }

    try {
      const boardJson = window.localStorage.getItem(`board:${boardId}`);
      if (boardJson) {
        const boardObject = Convert.toBoard(boardJson);
        if (boardObject) {
          dispatch(initializeBoard(boardObject));
        }
      }
    } catch (error) {
      window.localStorage.removeItem(`board:${boardId}`);
      logError("Error reading board cache");
    }
  }, [board, boardId, dispatch]);

  // load board
  useEffect(() => {
    if (!uid || !boardId) {
      return;
    }

    const boardSubscription = boardStream(boardId)
      .pipe(
        catchError((error) => {
          if (error instanceof Error) {
            // cannot find the board.
            if (error.message === "Missing or insufficient permissions.") {
              if (boardId === reflection?.defaultBoardId) {
                // load default board failed...bad
              } else {
                setForbidden(true);
                // remove from board list.
                unfollowBoard(uid, boardId);
              }
            }
          }

          return of(null);
        })
      )
      .subscribe(async (data) => {
        if (data) {
          dispatch(initializeBoard(data));
          window.localStorage.setItem(`board:${data.id}`, Convert.boardToJson(data));
        }
      });

    return () => {
      boardSubscription.unsubscribe();
    };
  }, [boardId, dispatch, reflection?.defaultBoardId, uid]);

  useEffect(() => {
    if (!uid || !reflection || !reflection.boards) {
      return;
    }

    const boardsSubscription = boardsStream(reflection.boards).subscribe(async (data) => {
      if (data) {
        dispatch(initializeBoards(data));
      }
    });

    return () => {
      boardsSubscription.unsubscribe();
    };
  }, [dispatch, reflection, uid]);

  // load prompts if board is available
  useEffect(() => {
    if (!uid || !board?.id) {
      return;
    }
    const reflectionAndPromptSubscription = boardPromptsStream(board?.id).subscribe(async (prompts) => {
      const promptDict = prompts.reduce((map, el) => {
        map[el.id!] = el;
        return map;
      }, {} as { [key: string]: Prompt });
      dispatch(initializePrompts(promptDict));
    });
    return () => {
      reflectionAndPromptSubscription.unsubscribe();
    };
  }, [board?.id, dispatch, uid]);

  // load subscriptions
  useEffect(() => {
    if (uid) {
      const subscriptionSubscription = userSubscriptionStream(uid).subscribe(async (subscriptions) => {
        dispatch(initializeSubscriptions(subscriptions));
      });
      const paymentSubscription = userPaymentStream(uid).subscribe(async (payments) => {
        dispatch(initializePayments(payments));
      });
      return () => {
        subscriptionSubscription.unsubscribe();
        paymentSubscription.unsubscribe();
      };
    }
  }, [dispatch, uid]);

  useEffect(() => {
    if (uid) {
      getPrices().then((p) => dispatch(loadProducts(p)));
    }
  }, [dispatch, uid]);

  const loading = !reflection;

  return (
    <div className="App">
      {!loading && !creating && !forbidden && (
        <UserProvider value={user}>
          <>{props.children}</>
        </UserProvider>
      )}
      {forbidden && (
        <div className="Loading">
          <h2>Oops, the board cannot be found.</h2>
          <Button href="/">Back</Button>
        </div>
      )}
      {creating && <div className="Loading">Setting up your account...</div>}
      {loading && <div className="Loading">Loading...</div>}
    </div>
  );
}

export default App;
