import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import Fade from "@material-ui/core/Fade";
import { People, Settings } from "@material-ui/icons";
import React, { useState } from "react";
import { navigate } from "@reach/router";

export default function BoardSettings({ boardId }: { boardId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function openMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function onEdit(_: React.MouseEvent<HTMLElement>) {
    navigate(`/app/${boardId}/edit`);
  }

  function onMembers(_: React.MouseEvent<HTMLElement>) {
    navigate(`/app/${boardId}/members`);
  }

  return (
    <>
      <div onClick={openMenu}>
        <IconButton aria-label="settings" size="small">
          <Settings />
        </IconButton>
      </div>
      <Menu
        className="BoardSettings"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        transitionDuration={125}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={onMembers}>
          <ListItemIcon>
            <People fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Members" />
        </MenuItem>
      </Menu>
    </>
  );
}
