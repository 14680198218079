import React, { MouseEvent, useEffect, useRef, useState } from "react";
import firebase from "firebase/app";
import { navigate, RouteComponentProps } from "@reach/router";

import Button from "@material-ui/core/Button";
import { Convert, Invite, RoleType } from "./Models";
import { useDispatch, useSelector } from "react-redux";
import { BrightnessController, BrightnessControllerConsumer, capitalize, UserConsumer } from "./Utils";
import { changeBoard, selectReflections } from "./store/ReflectionReducer";
import {
  addMemberProfile,
  boardInvitesStream,
  cancelBoardInvite,
  changeMemberRole,
  removeMember,
  sendBoardInvite,
} from "./db";
import "./Members.sass";
import TextField from "@material-ui/core/TextField";
import RoleSelector, { REMOVE_MEMBER_VALUE } from "./components/RoleSelect";
import { IconButton } from "@material-ui/core";
import { RemoveCircleOutline } from "@material-ui/icons";
import BoardSelector from "./components/BoardSelector";
import { isPro, isTeam, selectPlan } from "./store/PlanReducer";
import SubscriptionDialog from "./Subscription";
import { Icon } from "@iconify/react";
import WbSunny from "@material-ui/icons/WbSunny";

function Members(props: {
  user?: firebase.User | null;
  boardId?: string | null;
  controller: BrightnessController | null;
}) {
  const dispatch = useDispatch();
  const { reflection, board, boardId, boards } = useSelector(selectReflections);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
  const plan = useSelector(selectPlan);
  const textField = useRef<HTMLInputElement | null>(null);
  const [email, setEmail] = useState<string>("");
  const [invites, setInvites] = useState<Invite[]>([]);
  const [inviting, setInviting] = useState<boolean>(false);
  const subscribed = isPro(plan);
  const unlimitedInvite = isTeam(plan);

  useEffect(() => {
    // force board
    const requestedBoardId = props.boardId ?? reflection?.defaultBoardId;
    if (requestedBoardId && requestedBoardId !== boardId) {
      dispatch(changeBoard(requestedBoardId));
      navigate(`/app/${requestedBoardId}/members`);
    }
  }, [boardId, dispatch, props.boardId, reflection?.defaultBoardId]);

  useEffect(() => {
    if (!board?.id) {
      return;
    }

    const invitesSubscription = boardInvitesStream(board.id).subscribe(async (data) => {
      setInvites(data);
      setInviting(false);
    });

    return () => {
      invitesSubscription.unsubscribe();
    };
  }, [board?.id, dispatch]);

  function onDone(event: MouseEvent<HTMLElement>) {
    navigate(`/app/${boardId}`);
  }

  function canInviteMore(): boolean {
    if (!subscribed) {
      return false;
    }

    if (unlimitedInvite) {
      return true;
    }

    const memberLength = Object.values(board?.members ?? {}).length ?? 0;
    const pendingInviteLength = invites.length;

    if (subscribed && memberLength + pendingInviteLength < 2) {
      return true;
    }

    return false;
  }

  function onInvite() {
    if (inviting || !email || email.trim() === "") {
      return;
    }

    setInviting(true);

    if (!canInviteMore()) {
      setOpenSubscriptionModal(true);
      return;
    }

    if (boardId && email && email.trim() !== "" && props.user) {
      sendBoardInvite(props.user, boardId, email.trim(), RoleType.Editor);
    }

    setEmail("");
    if (textField.current) {
      textField.current.value = "";
    }
  }

  function onCancel(id: string) {
    if (boardId && id && props.user) {
      cancelBoardInvite(props.user, boardId, id);
    }
  }

  function onChangeRole(uid: string, newRole: string) {
    if (boardId && props.user) {
      if (newRole === REMOVE_MEMBER_VALUE) {
        removeMember(props.user, boardId, uid);
      } else {
        changeMemberRole(props.user, boardId, uid, Convert.toRoleType(`"${newRole}"`));
      }
    }
  }

  function onToggleBrightness() {
    props.controller?.setDark(!(props.controller?.dark ?? false));
  }

  function onChangeBoard(value: string) {
    if (value === "") {
      navigate(`/app/${reflection?.defaultBoardId ?? ""}/members`);
      return;
    }
    navigate(`/app/${value}/members`);
  }

  useEffect(() => {
    if (board && props.user && (!board.memberProfiles || !board.memberProfiles[props.user.uid])) {
      // add current user to member profiles.
      addMemberProfile(props.user, board.id!);
    }
  }, [board, props.user, props?.user?.uid]);

  const selectedBoardId = board?.id ?? "";

  return (
    <div className={`CanvasWrapper ${props.controller?.dark ?? false ? "Dark" : ""}`}>
      <div className="CanvasMenu">
        <div className="CanvasTitle">
          <img
            className="logo"
            src={`${process.env.PUBLIC_URL}/${props.controller?.dark ? "logo-dark.svg" : "logo.svg"}`}
            alt="Jot"
          />
          <div onClick={onToggleBrightness}>
            <IconButton aria-label="brightness" size="small">
              {props.controller?.dark ?? true ? <Icon icon="akar-icons:moon-fill" /> : <WbSunny />}
            </IconButton>
          </div>
        </div>
        <BoardSelector
          key={boards?.map((b) => b.id)?.join("-") ?? "board-selector"}
          selectedBoardId={selectedBoardId}
          board={board}
          boards={boards}
          onChangeBoard={onChangeBoard}
          showNew={false}
        />
        <div className="CanvasControls">
          <Button aria-label="exit" size="small" variant="contained" color="primary" disableElevation onClick={onDone}>
            Done
          </Button>
        </div>
      </div>
      <div className="Members">
        <div className="CenterWrapper">
          <div className="Members-List">
            <h2>Manage members</h2>
            {Object.keys(board?.members ?? {}).map((id) => {
              const profile = (board?.memberProfiles ?? {})[id];
              const role = (board?.members ?? {})[id] ?? RoleType.Viewer;
              const isCurrentUser = id === props.user?.uid;
              const isOwner = role === RoleType.Owner;
              return (
                <div className="Member" key={id}>
                  <div>
                    <span>
                      {isCurrentUser
                        ? `${profile?.name ?? profile?.email ?? id} (You)`
                        : profile.name ?? profile?.email ?? id}
                    </span>
                  </div>
                  <RoleSelector
                    selectedRole={role}
                    roles={[RoleType.Owner, RoleType.Editor, RoleType.Viewer]}
                    onChangeRole={(newRole: string) => onChangeRole(id, newRole)}
                    showRemove={!isCurrentUser && !isOwner}
                    disabled={isCurrentUser}
                  />
                </div>
              );
            })}
          </div>
          <div className="Invites-List">
            <h2>Manage invites</h2>
            <div className="Invites">
              {invites.map((i) => {
                return (
                  <div className="Invitation" key={i.id}>
                    <div className="Email">{i.email}</div>
                    <div>{capitalize(i.initialRole ?? RoleType.Viewer)}</div>
                    <IconButton aria-label="cancel" size="small" onClick={() => onCancel(i.id!)}>
                      <RemoveCircleOutline />
                    </IconButton>
                  </div>
                );
              })}
              {inviting && <div>Inviting...</div>}
            </div>
            <div className="Invite-Input">
              <TextField
                onChange={(event) => setEmail(event?.currentTarget?.value ?? "")}
                id="email"
                label="Email"
                fullWidth
                variant="outlined"
                type="email"
                data-lpignore="true"
                value={email}
                ref={textField}
              />
              <Button
                className="Add"
                aria-label="add"
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={onInvite}
              >
                Invite
              </Button>
            </div>
          </div>
        </div>
      </div>
      {props.user && (
        <SubscriptionDialog
          open={openSubscriptionModal}
          uid={props.user.uid}
          onClose={() => setOpenSubscriptionModal(false)}
        />
      )}
    </div>
  );
}

function MembersWithUser({ board }: { board?: string | null } & RouteComponentProps) {
  return (
    <UserConsumer>
      {(user) => {
        return (
          <BrightnessControllerConsumer>
            {(controller) => {
              return <Members user={user} boardId={board} controller={controller} />;
            }}
          </BrightnessControllerConsumer>
        );
      }}
    </UserConsumer>
  );
}

export default MembersWithUser;
