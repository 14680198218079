import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { navigate } from "@reach/router";
import { deleteBoard } from "../db";
import { DialogContentText } from "@material-ui/core";

export default function DeleteBoardDialog(props: {
  open: boolean;
  uid: string;
  boardName: string;
  boardId: string;
  onClose: () => void;
}) {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [text, setText] = useState<string>("");

  async function onDeleteBoard() {
    if (deleting || !text || text !== "DELETE") {
      return;
    }

    try {
      setDeleting(true);
      setError(null);
      await deleteBoard(props.uid, props.boardId);
      // go to home
      navigate("/app");
    } catch {
      setError("Error deleting board. Please refresh the page and try again");
    } finally {
      setDeleting(false);
    }
  }
  return (
    <Dialog
      className="Delete-Board-Dialog"
      onClose={props.onClose}
      aria-labelledby="Delete-Board-Dialog-Title"
      open={props.open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="Delete-Board-Dialog-Title">Delete board '{props.boardName}'?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>Please enter 'DELETE' below to confirm deleting this board.</span>
          {error}
        </DialogContentText>
        <TextField
          onChange={(event) => setText(event?.currentTarget?.value ?? "")}
          autoFocus
          id="confirm"
          label="Confirm deletion"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={onDeleteBoard} color="secondary" variant="contained" disableElevation>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
