import React, { useState } from "react";
import { Scrollbars, ScrollbarProps, positionValues } from "react-custom-scrollbars";
import "./ScrollShadow.sass";

function ShadowScrollbars(props: { dark?: boolean } & ScrollbarProps) {
  const [shadowTopOpacity, setShadowTopOpacity] = useState<number>(0);
  const [shadowBottomOpacity, setShadowBottomOpacity] = useState<number>(0);

  function handleUpdate(values: positionValues) {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity = (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    setShadowTopOpacity(shadowTopOpacity);
    setShadowBottomOpacity(shadowBottomOpacity);
  }

  const containerStyle = {
    position: "relative",
    flex: 1,
  } as React.CSSProperties;

  const shadowTopStyle = {
    height: 4,
    background: props.dark
      ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)"
      : "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%)",
    opacity: shadowTopOpacity,
  } as React.CSSProperties;

  const shadowBottomStyle = {
    height: props.dark ? 12 : 6,
    background: props.dark
      ? "linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)"
      : "linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)",
    opacity: shadowBottomOpacity,
  } as React.CSSProperties;

  const cleanProps = { ...props };
  delete cleanProps["dark"];

  return (
    <div style={containerStyle}>
      <Scrollbars className="ShadowScrollbars" onUpdate={handleUpdate} {...cleanProps} />
      <div className="TopShadow" style={shadowTopStyle} />
      <div className="BottomShadow" style={shadowBottomStyle} />
    </div>
  );
}

export default ShadowScrollbars;
