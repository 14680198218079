import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Answer } from '../Models';
import { conceptualToDisplayYearMonth } from '../Utils';
import { RootState } from './Store';

export interface Section {
  isMonth?: boolean;
  date: string;
  answers: Answer[];
}

export interface Journal {
  answers: Answer[];
  promptIds: string[];
  sections: Section[];
}

const initialState: Journal = { answers: [], promptIds: [], sections: [] } as Journal;

function buildSections(answers: Answer[]): Section[] {
  if (answers.length === 0) {
    return [];
  }

  const result: Section[] = [];
  let currentYearMonth: string | undefined;
  let conceptualDate: string | undefined;
  for (let answer of answers) {
    if (answer.text?.trim() === "") {
      continue;
    }

    if (!currentYearMonth) {
      currentYearMonth = conceptualToDisplayYearMonth(answer.conceptual_date ?? "")
    }

    if (!conceptualDate) {
      conceptualDate = answer.conceptual_date;
    }

    if (result.length === 0) {
      result.push({ isMonth: true, date: conceptualDate, answers: [] } as Section, { date: conceptualDate, answers: [] } as Section)
    }

    if (answer.conceptual_date === conceptualDate) {
      result[result.length - 1].answers.push(answer);
    } else {
      // add
      const answerYearMonth = conceptualToDisplayYearMonth(answer.conceptual_date ?? "");
      if (answerYearMonth !== currentYearMonth) {
        // console.log(answerYearMonth + " | " + currentYearMonth)
        currentYearMonth = answerYearMonth;
        result.push({ isMonth: true, date: answer.conceptual_date, answers: [] } as Section);
      }

      conceptualDate = answer.conceptual_date;
      result.push({ date: conceptualDate, answers: [answer] } as Section);
    }
  }

  return result.filter(s => s.answers.length > 0 || s.isMonth);
}

export const journalSlice = createSlice({
  name: 'journals',
  initialState,
  reducers: {
    changePromptIds: (current, action: PayloadAction<string[]>) => {
      current.promptIds = action.payload ?? [];
      current.answers = [];
      current.sections = [];
      return current;
    },
    invalidateJournal: (current) => {
      current.promptIds = [];
      current.answers = [];
      current.sections = [];
      return current;
    },
    updateAnswers: (current, action: PayloadAction<Answer[]>) => {
      current.answers = [...current.answers, ...action.payload];
      current.sections = buildSections(current.answers);
      return current;
    }
  },
});

export const { changePromptIds, updateAnswers, invalidateJournal } = journalSlice.actions;

export const selectJournal = (state: RootState) => state.journal;

export default journalSlice.reducer;

