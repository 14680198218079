import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from "firebase/app";
import { v4 as uuid } from "uuid";
import { boardLatestAnswerForPromptQuery, db, refToLegacyAnswers, refToLegacyAnswerVersions, refToLegacyPrompts, refToReflectionBoard, refToReflectionBoardAnswer, refToReflectionBoardAnswerVersion, refToReflectionBoardPrompt, refToUserReflections, serverTimestamp } from '../db';
import { Answer, Board, BoardMemberProfile, BoardType, Column, MindfulReflections, Prompt, PromptLifecycle, RoleType } from '../Models';
import { logError } from '../Utils';
import { AppThunk, RootState } from './Store';

interface State {
  // specified in the url, used to load specified board
  boardId?: string,
  // loaded from the db, used to load default board
  reflection?: MindfulReflections,
  // loaded from the db, used to populate board list
  boards?: Board[],
  // loaded based on current board
  prompts?: { [key: string]: Prompt },
  // loaded based on current board
  board?: Board,
}

const initialState: State = {};

function getInitialState(user: firebase.User): State {
  const prompts: { [key: string]: Prompt } = {};

  const defaultWorkQuestions: Prompt[] = [
    "What will make today a success?",
    "What did you accomplish today?",
    "What could be better?",
  ].map(q => ({ question: q, id: uuid() } as Prompt));

  const defaultPersonalQuestions: Prompt[] = [
    "What are you grateful for?",
    "What bothers you now?",
    "What are you excited about?",
  ].map(q => ({ question: q, id: uuid() } as Prompt));

  defaultWorkQuestions.forEach(p => prompts[p.id!] = p);
  defaultPersonalQuestions.forEach(p => prompts[p.id!] = p);

  const columns = [
    {
      id: uuid(),
      name: "Work",
      color: "#847FFF",
      prompts: defaultWorkQuestions.map(q => q.id),
    } as Column,
    {
      id: uuid(),
      name: "Personal",
      color: "#95D8C5",
      prompts: defaultPersonalQuestions.map(q => q.id),
    } as Column,
  ];

  const uid = user.uid;

  const members: { [key: string]: RoleType } = { [uid]: RoleType.Owner };
  const memberProfiles: { [key: string]: BoardMemberProfile } = {
    [uid]: {
      name: user.displayName,
      email: user.email,
      profile_picture: user.photoURL,
    } as BoardMemberProfile
  };

  const defaultBoardId = uuid();
  const board = {
    id: defaultBoardId,
    columns,
    description: "Personal board",
    members,
    memberProfiles,
    name: "Personal board",
    published: false,
    type: BoardType.Daily,
  } as Board;

  return {
    reflection: {
      initialized: true,
      version: "v1",
      defaultBoardId,
      boards: [defaultBoardId],
      columns: [], // deprecated
    } as MindfulReflections,
    prompts,
    board,
  } as State;
};

export const reflectionSlice = createSlice({
  name: 'reflections',
  initialState,
  reducers: {
    initializeReflections: (current: State, action: PayloadAction<MindfulReflections>) => {
      current.reflection = action.payload;
      return current;
    },
    initializePrompts: (current: State, action: PayloadAction<{ [key: string]: Prompt }>) => {
      current.prompts = action.payload;
      return current;
    },
    initializeBoard: (current: State, action: PayloadAction<Board>) => {
      current.board = action.payload;
      return current;
    },
    initializeBoards: (current: State, action: PayloadAction<Board[]>) => {
      current.boards = action.payload;
      return current;
    },
    changeBoard: (current: State, action: PayloadAction<string>) => {
      if (action.payload && current.boardId !== action.payload) {
        return {
          // unset prompts and board to reload them.
          reflection: current.reflection,
          boardId: action.payload,
          boards: current.boards ?? [],
        }
      }
      return current;
    },
    updateBoardName: (current: State, action: PayloadAction<string[]>) => {
      if (action.payload) {
        const [boardId, newName] = action.payload;
        if (boardId === current.board?.id) {
          current.board.name = newName;
        }
      }
      return current;
    },
    updatePrompt: (current: State, action: PayloadAction<Prompt>) => {
      if (action.payload.id && current.prompts) {
        current.prompts[action.payload.id] = action.payload;
      }
      return current;
    },
    updateColumns: (current: State, action: PayloadAction<Column[]>) => {
      if (action.payload && current.board) {
        current.board.columns = action.payload;
      }
      return current;
    },
    updateColumnsAndPrompts: (current: State, action: PayloadAction<{ columns: Column[], prompts: { [key: string]: Prompt } }>) => {
      if (action.payload.columns && current.board) {
        current.board.columns = action.payload.columns;
      }
      if (action.payload.prompts && current.prompts) {
        current.prompts = action.payload.prompts;
      }
      return current;
    }
  },
});

export const { initializeReflections, initializePrompts, initializeBoard, initializeBoards, changeBoard, updateBoardName, updatePrompt, updateColumns, updateColumnsAndPrompts } = reflectionSlice.actions;

export const createReflections = (user: firebase.User): AppThunk => async dispatch => {
  // console.log("create");
  try {
    const uid = user.uid;
    const state = getInitialState(user);

    // make sure it doesn't exist yet.
    const existing = await refToUserReflections(uid).get();
    if (!existing || !existing.data() || !existing.data()?.initialized) {
      // create reflection object
      await refToUserReflections(uid).set(state.reflection!);

      const boardId = state.reflection!.defaultBoardId!

      // create default board
      await refToReflectionBoard(boardId).set(state.board!)

      // create prompts under the board
      const prompts: Prompt[] = Object.values(state.prompts!);
      await Promise.all(prompts.map(p => refToReflectionBoardPrompt(boardId, p.id!).set(p)))
    }
  } catch (error) {
    logError(error);
  }
};

export const migrateDefaultBoard = (user: firebase.User): AppThunk => async dispatch => {
  try {
    const uid = user.uid;
    // make sure it doesn't exist yet.
    const existing = await refToUserReflections(uid).get();
    if (existing && existing.data() && existing.data()?.initialized && existing.data()?.columns) {
      const updatedData = existing.data();
      if (!updatedData) {
        // todo: show broken screen
        return;
      }

      const boardId = uuid();

      const members: { [key: string]: RoleType } = { [uid]: RoleType.Owner };
      const memberProfiles: { [key: string]: BoardMemberProfile } = {
        [uid]: {
          name: user.displayName,
          email: user.email,
          profile_picture: user.photoURL,
        } as BoardMemberProfile
      };

      const board = {
        id: boardId,
        columns: updatedData?.columns,
        description: "Personal board",
        members,
        memberProfiles,
        name: "Personal board",
        published: false,
        type: BoardType.Daily,
      } as Board;

      // create default board
      try {
        await db.runTransaction(async (transaction) => {
          // patch reflection
          if (!updatedData.boards || updatedData.boards.length === 0 || !updatedData.defaultBoardId) {
            updatedData.boards = [boardId];
            updatedData.defaultBoardId = boardId;
          }

          transaction.set(refToReflectionBoard(boardId), (board));
          transaction.update(refToUserReflections(uid), updatedData);
        });
      } catch (error) {
        logError(`error with creating board ${error}`);
        // no need to process the rest
        return;
      }

      // copy prompts
      try {
        const oldPrompts = await refToLegacyPrompts(uid).get();
        if (oldPrompts && oldPrompts.docs) {
          await Promise.all(oldPrompts.docs.map(p => refToReflectionBoardPrompt(boardId, p.id!).set(p.data())))
        }
      } catch (error) {
        logError(`error with prompts ${error}`);
      }

      // copy answers
      try {
        const oldAnswers = await refToLegacyAnswers(uid).get();
        if (oldAnswers && oldAnswers.docs) {
          // console.log(oldAnswers);
          await Promise.all(oldAnswers.docs.map(a => refToReflectionBoardAnswer(boardId, a.id!).set(a.data())))

          // copy versions
          await Promise.all(oldAnswers.docs.map(async (a) => {
            const versions = await refToLegacyAnswerVersions(uid, a.id).get()
            if (versions && versions.docs) {
              await Promise.all(versions.docs.map(v => {
                // console.log(v.data());
                return refToReflectionBoardAnswerVersion(boardId, a.id!, v.id!).set(v.data());
              }))
            }
          }))
        }
      } catch (error) {
        logError(`error with answers ${error}`);
      }
    } else {
      // didn't exist, let's create
      dispatch(createReflections(user));
    }
  } catch (error) {
    logError(error);
  }
};

export const savePrompt = (boardId: string, prompt: Prompt): AppThunk => async dispatch => {
  const promptSnap: firebase.firestore.DocumentSnapshot<Prompt> = await refToReflectionBoardPrompt(boardId, prompt.id!).get();
  let newPrompt: Prompt | undefined;
  if (promptSnap && promptSnap.data()) {
    // update
    try {
      // if we can't find it, then it doesn't exist. save it as well.
      const additionalUpdate = {
        latest_answer_id: prompt.latest_answer_id ?? null,
        latest_answer_conceptual_date: prompt.latest_answer_conceptual_date ?? null,
      };

      if (prompt.answers && prompt.answers.length > 0 && !prompt.latest_answer_id) {
        // prompt has answers but we don't track the latest answer, patch it.
        const latestAnswerSnap: firebase.firestore.QuerySnapshot<Answer> = await boardLatestAnswerForPromptQuery(boardId, promptSnap.id).get();
        let firstDoc: firebase.firestore.QueryDocumentSnapshot<Answer> | undefined;
        latestAnswerSnap.forEach(doc => {
          firstDoc = doc;
        });
        // what if we can't find answer but .answers is not empty?
        additionalUpdate.latest_answer_id = firstDoc?.id ?? null;
        additionalUpdate.latest_answer_conceptual_date = firstDoc?.data()?.conceptual_date ?? null;
      }

      await promptSnap.ref.update({ question: prompt.question, lifecycle: prompt.lifecycle ?? PromptLifecycle.Daily, updated_at: serverTimestamp(), ...additionalUpdate });
      newPrompt = promptSnap.data();
      if (newPrompt) {
        newPrompt.question = prompt.question;
        newPrompt.lifecycle = prompt.lifecycle ?? PromptLifecycle.Daily;
      }
    } catch (error) {
      logError(error);
    }
  } else {
    // create
    try {
      await refToReflectionBoardPrompt(boardId, prompt.id!).set(prompt);
      const newDoc = await refToReflectionBoardPrompt(boardId, prompt.id!).get();
      const data = newDoc.data();
      if (data) {
        newPrompt = data;
      }
    } catch (error) {
      logError(error);
    }
  }

  if (newPrompt) {
    dispatch(updatePrompt(newPrompt));
  }
};

export const deletePrompt = (boardId: string, promptId: string): AppThunk => async dispatch => {
  await refToReflectionBoardPrompt(boardId, promptId).delete();
};

export const saveColumns = (boardId: string, columns: Column[]): AppThunk => async dispatch => {
  dispatch(updateColumns(columns));
  await refToReflectionBoard(boardId).update({ columns, updated_at: serverTimestamp() });
};

export const updateBoard = (boardId: string, newName: string): AppThunk => async dispatch => {
  dispatch(updateBoardName([boardId, newName]));
  await refToReflectionBoard(boardId).update({ name: newName, updated_at: serverTimestamp() });
};

export const selectReflections = (state: RootState) => state.reflections;

export default reflectionSlice.reducer;
