import React, { useEffect, useRef, useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import Countdown from "react-countdown";

import "./Marketing.sass";
import { Icon } from "@iconify/react";
import { authState } from "rxfire/auth";
import app from "./db";
import { add, startOfHour } from "date-fns";

function Marketing(_: RouteComponentProps) {
  const pricingRef = useRef<HTMLDivElement | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  function scrollToPricing(event: React.MouseEvent<HTMLAnchorElement>) {
    pricingRef?.current?.scrollIntoView();
    event.preventDefault();
    return false;
  }

  useEffect(() => {
    authState(app.auth()).subscribe((u) => {
      setLoggedIn(Boolean(u));
    });
  });

  return (
    <div className="Marketing">
      <div className="Section Top">
        <div>
          <Link color="inherit" to="#" onClick={scrollToPricing} className="Discount">
            50% off - Limited time
          </Link>
        </div>
        <div>
          Ends in &nbsp;
          <Countdown daysInHours date={add(startOfHour(new Date()), { hours: 4 })} />
        </div>
        <div>
          {loggedIn && (
            <Link color="inherit" to={`/app`} className="GoToApp">
              Go to app
            </Link>
          )}
          {!loggedIn && (
            <Link color="inherit" to={`/sign_up`} className="GetStarted">
              Get started for free
            </Link>
          )}
        </div>
      </div>
      <div className="Section Header">
        <img className="Bg" src={`${process.env.PUBLIC_URL}/infinity-large.png`} alt="Infinity symbol" />
        <h1>Jot</h1>
        <div className="TagLine">Writing for life</div>
        <img className="Logo" src={`${process.env.PUBLIC_URL}/logo-large.png`} alt="Jot Logo" />
      </div>
      <div className="Section">
        <h2>Benefits</h2>
        <ul>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            Keep your thoughts as organized as you like
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            One journal for each topic? You got it
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            Never carry heavy physical planners and journals
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            No more abandoned half-empty journals
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            Find notes and memos easily with search*
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            Suitable for personal productivity, mindfulness, or team collaboration
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            Automatic word counting and streak tracking
          </li>
          <li className="Positive">
            <Icon icon="akar-icons:check-box-fill" />
            Paperless. Good for Earth
          </li>
        </ul>
        <Link color="inherit" to={`/sign_up`} className="GetStarted">
          Sign up for free
        </Link>
      </div>
      <div className="Section">
        <h2>Features</h2>
        <div className="Areas">
          <div className="Area">
            <h4>Make it your own</h4>
            <ul>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Use as a planner, a journal, or a brainstorm tool
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Create unlimited boards, lists, and prompts
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Choose from preset templates
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Fully customizable prompts
              </li>
            </ul>
          </div>

          <div className="Area">
            <h4>Easy and organized</h4>
            <ul>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Minimalist clutter-free UI
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Open your journals instantly
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Automatically opens to the current date
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Auto saves your notes and counts words
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Review all your notes by date, by topic, or both
              </li>
            </ul>
          </div>

          <div className="Area">
            <h4>Share with others</h4>
            <ul>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Collaborate with others with shared boards
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Share it with your coach or partner
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Write and hold each other accountable
              </li>
              <li className="Feature">
                <Icon icon="heroicons-outline:badge-check" />
                Content synced seamlessly
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Section" ref={pricingRef}>
        <h2>Pricing</h2>
        <div className="Pricing">
          <div className="Tier FreeTier">
            <h4>
              Basic
              <br />
              Free forever
              <br />
              <br />
            </h4>
            <ul>
              <li className="Feature">2 boards max</li>
              <li className="Feature">16 prompts per board</li>
              <li className="Feature">Calendar view</li>
              <li className="Feature">Auto word count</li>
            </ul>
          </div>
          <div className="Tier Pro">
            <h4>
              Pro (Annual)
              <br />
              $59
              <br />
              Less than $5/month
            </h4>
            <ul>
              <li className="Feature">Infinite boards</li>
              <li className="Feature">Infinite prompts</li>
              <li className="Feature">Calendar view</li>
              <li className="Feature">Auto word count</li>
              <li className="Feature">Up to 2 members</li>
              <li className="Feature">Premium templates</li>
              <li className="Feature">Priority support</li>
              <li className="Feature">Cancel anytime</li>
            </ul>
          </div>
          <div className="Tier ProLife">
            <h4 className="Highlight">
              Pro (Lifetime)
              <br />
              <span className="Old">$399</span> $199
              <br />
              50% Off - Limited time
            </h4>
            <ul>
              <li className="Feature">Infinite boards</li>
              <li className="Feature">Infinite prompts</li>
              <li className="Feature">Calendar view</li>
              <li className="Feature">Auto word count</li>
              <li className="Feature">Up to 2 members</li>
              <li className="Feature">Premium templates</li>
              <li className="Feature">Priority support</li>
              <li className="Feature">Access forever</li>
            </ul>
          </div>
          <div className="Tier Team">
            <h4>
              Team (Annual)
              <br />
              $149
              <br />
              <br />
            </h4>
            <ul>
              <li className="Feature">Infinite boards</li>
              <li className="Feature">Infinite prompts</li>
              <li className="Feature">Calendar view</li>
              <li className="Feature">Auto word count</li>
              <li className="Feature">Up to 100 members</li>
              <li className="Feature">Premium templates</li>
              <li className="Feature">Priority support</li>
              <li className="Feature">Cancel anytime</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Section Bottom">
        <img className="Shape" src={`${process.env.PUBLIC_URL}/infinity.svg`} alt="Infinity" />
        <h3>
          What's worth
          <br />
          jotting today?
        </h3>
        <Link color="inherit" to={`/sign_up`} className="GetStarted">
          Get started for free
        </Link>
      </div>
    </div>
  );
}

export default Marketing;
