import React, { useCallback, useEffect, useRef, useState } from "react";
import CanvasConfetti from "canvas-confetti";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
} as React.CSSProperties;

export default function Confetti(props: { duration: number }) {
  const animationInstance = useRef<CanvasConfetti.CreateTypes | null>(null);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const start = useRef<Date>(new Date());

  const makeShot = (angle: number, originX: number) => {
    animationInstance.current &&
      animationInstance.current({
        particleCount: 3,
        angle,
        spread: 55,
        origin: { x: originX },
        colors: ["#bb0000", "#ffffff"],
      });
  };

  const nextTickAnimation = useCallback(() => {
    makeShot(60, 0);
    makeShot(120, 1);
    if (new Date().valueOf() - start.current.valueOf() < props.duration) {
      requestAnimationFrame(nextTickAnimation);
    } else {
      setIsAnimating(false);
    }
  }, [props.duration]);

  useEffect(() => {
    if (!isAnimating) {
      setIsAnimating(true);
      nextTickAnimation();
    }
  }, [isAnimating, nextTickAnimation]);

  const getInstance = (confetti: CanvasConfetti.CreateTypes | null) => {
    animationInstance.current = confetti;
  };

  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </>
  );
}
