import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { v4 as uuid } from "uuid";

import { docData, collectionData } from "rxfire/firestore";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";
import { Answer, Board, BoardMemberProfile, BoardType, Convert, GetInvitePayload, Invite, MindfulReflections, Payment, Price, Product, Prompt, PromptLifecycle, RoleType, SendInvitePayload, Subscription } from "./Models";
import { formatConceptualDate, logError, parseConceptualDate } from "./Utils";
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, sub } from "date-fns";

const app = process.env.NODE_ENV === "production"
  ?
  firebase.initializeApp({
    apiKey: "AIzaSyAFXQzEhE9db_yQr3nByTF7VaP8uAoiJHI",
    authDomain: "mindful-e8ef6.firebaseapp.com",
    databaseURL: "https://mindful-e8ef6.firebaseio.com",
    projectId: "mindful-e8ef6",
    storageBucket: "mindful-e8ef6.appspot.com",
    messagingSenderId: "461951599387",
    appId: "1:461951599387:web:2e4c9019b08a0e11d049be",
    measurementId: "G-2ZN4LJT5CM",
  })
  :
  firebase.initializeApp({
    apiKey: "AIzaSyBWrm04sW_VCKg7mf_im4RP7771IZGkJuU",
    authDomain: "mindful-dev-9f1d9.firebaseapp.com",
    databaseURL: "https://mindful-dev-9f1d9.firebaseio.com",
    projectId: "mindful-dev-9f1d9",
    storageBucket: "mindful-dev-9f1d9.appspot.com",
    messagingSenderId: "872643535972",
    appId: "1:872643535972:web:9a44d1d3208463b7baeb71",
    measurementId: "G-RJLDJYPK70"
  });

const API_HOST = process.env.NODE_ENV === "production" ? "https://api.mindfulsuite.com" : "https://mindful-dev-9f1d9.uc.r.appspot.com"

export const db = firebase.firestore();
export const storage = firebase.storage();

export function serverTimestamp(): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.serverTimestamp();
}

export async function Api(user: firebase.User, path: string, data: any): Promise<any> {
  try {
    const token = await user.getIdToken();
    const headers = new Headers();
    headers.set('X-Mindful-Token', token);
    const res = await fetch(`${API_HOST}/${path}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    logError(error);
  }

  return null;
}

const inviteConverter = {
  toFirestore(invite: Invite): firebase.firestore.DocumentData {
    const safeData = JSON.parse(Convert.inviteToJson(invite));
    if (!safeData.created_at) {
      safeData.created_at = serverTimestamp();
    }
    safeData.updated_at = serverTimestamp();
    return safeData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Invite {
    const data = JSON.stringify(snapshot.data(options));
    const result = Convert.toInvite(data);
    return result
  }
};

const answerConverter = {
  toFirestore(answer: Answer): firebase.firestore.DocumentData {
    const safeData = JSON.parse(Convert.answerToJson(answer));
    if (!safeData.created_at) {
      safeData.created_at = serverTimestamp();
    }
    safeData.updated_at = serverTimestamp();
    return safeData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Answer {
    const data = JSON.stringify(snapshot.data(options));
    const result = Convert.toAnswer(data);
    result.id = snapshot.id;
    return result
  }
};

const promptConverter = {
  toFirestore(prompt: Prompt): firebase.firestore.DocumentData {
    const safeData = JSON.parse(Convert.promptToJson(prompt));
    if (!safeData.created_at) {
      safeData.created_at = serverTimestamp();
    }
    safeData.updated_at = serverTimestamp();
    return safeData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Prompt {
    const data = JSON.stringify(snapshot.data(options));
    const result = Convert.toPrompt(data);
    return result
  }
};

const boardConverter = {
  toFirestore(board: Board): firebase.firestore.DocumentData {
    const safeData = JSON.parse(Convert.boardToJson(board));
    if (!safeData.created_at) {
      safeData.created_at = serverTimestamp();
    }
    safeData.updated_at = serverTimestamp();
    return safeData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): Board {
    const data = JSON.stringify(snapshot.data(options));
    const result = Convert.toBoard(data);
    return result
  }
};

const reflectionsConverter = {
  toFirestore(user: MindfulReflections): firebase.firestore.DocumentData {
    const safeData = JSON.parse(Convert.mindfulReflectionsToJson(user));
    if (!safeData.created_at) {
      safeData.created_at = serverTimestamp();
    }
    safeData.updated_at = serverTimestamp();
    return safeData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): MindfulReflections {
    const data = JSON.stringify(snapshot.data(options));
    const result = Convert.toMindfulReflections(data);
    return result
  }
};

export const refToUserReflections = (uid: string): firebase.firestore.DocumentReference<MindfulReflections> => {
  return db.collection("mindful_reflections").doc(uid).withConverter(reflectionsConverter);
};

export const refToReflectionBoard = (boardId: string): firebase.firestore.DocumentReference<Board> => {
  return db.collection("mindful_boards").doc(boardId).withConverter(boardConverter);
};

export const refToReflectionBoardPrompt = (boardId: string, promptId: string): firebase.firestore.DocumentReference<Prompt> => {
  return db.collection("mindful_boards").doc(boardId).collection("prompts").doc(promptId).withConverter(promptConverter);
};

export const refToReflectionBoardPrompts = (boardId: string): firebase.firestore.CollectionReference<Prompt> => {
  return db.collection("mindful_boards").doc(boardId).collection("prompts").withConverter(promptConverter);
};

export const refToReflectionBoardAnswers = (boardId: string): firebase.firestore.CollectionReference<Answer> => {
  return db.collection("mindful_boards").doc(boardId).collection("answers").withConverter(answerConverter);
};

export const refToReflectionBoardAnswer = (boardId: string, answerId: string): firebase.firestore.DocumentReference<Answer> => {
  return db.collection("mindful_boards").doc(boardId).collection("answers").doc(answerId).withConverter(answerConverter);
};

export const refToReflectionBoardAnswerVersions = (boardId: string, answerId: string): firebase.firestore.CollectionReference<Answer> => {
  return db.collection("mindful_boards").doc(boardId).collection("answers").doc(answerId).collection("versions").withConverter(answerConverter);
};

export const refToReflectionBoardAnswerVersion = (boardId: string, answerId: string, versionId: string): firebase.firestore.DocumentReference<Answer> => {
  return db.collection("mindful_boards").doc(boardId).collection("answers").doc(answerId).collection("versions").doc(versionId).withConverter(answerConverter);
};

export const refToReflectionBoardAnswerByPromptAndDate = (boardId: string, promptId: string, conceptualDate: string): firebase.firestore.Query<Answer> => {
  return db.collection("mindful_boards").doc(boardId).collection("answers").withConverter(answerConverter).where("prompt_id", "==", promptId).where("conceptual_date", "==", conceptualDate).orderBy("created_at", "desc").limit(1);
};

export const refToReflectionBoardInvites = (boardId: string): firebase.firestore.CollectionReference<Invite> => {
  return db.collection("mindful_boards").doc(boardId).collection("invites").withConverter(inviteConverter);
};

export const sendBoardInvite = async (user: firebase.User, boardId: string, email: string, role: string): Promise<any> => {
  return Api(user, "journals/invite/send", {
    boardId,
    email,
    role,
  } as SendInvitePayload);
};

export const getBoardInvite = async (user: firebase.User, boardId: string, inviteId: string, code: string): Promise<any> => {
  return Api(user, "journals/invite/get", {
    boardId,
    id: inviteId,
    code,
  } as GetInvitePayload);
};

export const acceptBoardInvite = async (user: firebase.User, boardId: string, inviteId: string, code: string): Promise<any> => {
  return Api(user, "journals/invite/accept", {
    boardId,
    id: inviteId,
    code,
  } as GetInvitePayload);
};

export const ignoreBoardInvite = async (user: firebase.User, boardId: string, inviteId: string, code: string): Promise<any> => {
  return Api(user, "journals/invite/ignore", {
    boardId,
    id: inviteId,
    code,
  } as GetInvitePayload);
};

export const cancelBoardInvite = async (user: firebase.User, boardId: string, id: string): Promise<any> => {
  try {
    const result = await refToReflectionBoardInvites(boardId).where("id", "==", id).where("cancelled", "==", false).where("used", "==", false).get();
    if (result && result.docs.length > 0) {
      await result.docs[0].ref.update({ cancelled: true, cancelledAt: new Date().valueOf() });
    }
    return true;
  } catch (error) {
    logError(error);
  }

  return false;
};


export const removeMember = async (user: firebase.User, boardId: string, uid: string): Promise<any> => {
  try {
    // delete the member from the dictionary.
    await refToReflectionBoard(boardId).update({ [`members.${uid}`]: firebase.firestore.FieldValue.delete() });
    return true;
  } catch (error) {
    logError(error);
  }

  return false;
};

export const changeMemberRole = async (user: firebase.User, boardId: string, uid: string, newRole: RoleType): Promise<any> => {
  try {
    // change the member role.
    await refToReflectionBoard(boardId).update({ [`members.${uid}`]: newRole });
    return true;
  } catch (error) {
    logError(error);
  }

  return false;
};

export const addMemberProfile = async (user: firebase.User, boardId: string): Promise<any> => {
  try {
    // change the member role.
    await refToReflectionBoard(boardId).update({
      [`memberProfiles.${user.uid}`]: {
        name: user.displayName,
        email: user.email,
        profile_picture: user.photoURL,
      }
    });
    return true;
  } catch (error) {
    logError(error);
  }

  return false;
};

export const userReflectionsStream = (uid: string): Observable<MindfulReflections> => {
  return docData<MindfulReflections>(refToUserReflections(uid)).pipe(debounceTime<MindfulReflections>(1000));
};

export const createBoard = async (user: firebase.User, name: string): Promise<string> => {
  const newBoardId = uuid();
  const uid = user.uid;
  const existing = await refToUserReflections(uid).get();
  if (!existing || !existing.data()) {
    // nothing to do
    return "";
  }

  const members: { [key: string]: RoleType } = { [uid]: RoleType.Owner };
  const memberProfiles: { [key: string]: BoardMemberProfile } = {
    [uid]: {
      name: user.displayName,
      email: user.email,
      profile_picture: user.photoURL,
    } as BoardMemberProfile
  };

  const board = {
    id: newBoardId,
    columns: [],
    description: "A new board",
    members,
    memberProfiles,
    name: name ?? "Personal board",
    published: false,
    type: BoardType.Daily,
  } as Board;

  try {
    await db.runTransaction(async (transaction) => {
      // create new board
      transaction.set(refToReflectionBoard(newBoardId), board);
      // add new board id to the array.
      transaction.update(refToUserReflections(uid), { boards: firebase.firestore.FieldValue.arrayUnion(newBoardId) });
    });
    return newBoardId;
  } catch (error) {
    logError(`error with creating board ${error}`);
  }
  return "";
};

export const deleteBoard = async (uid: string, boardId: string): Promise<void> => {
  const existing = await refToUserReflections(uid).get();
  if (!existing || !existing.data()) {
    // nothing to do
    return;
  }

  try {
    await db.runTransaction(async (transaction) => {
      // remove self from board member list
      transaction.update(refToReflectionBoard(boardId), { [`members.${uid}`]: firebase.firestore.FieldValue.delete() });
      // remove board id to the array.
      transaction.update(refToUserReflections(uid), { boards: firebase.firestore.FieldValue.arrayRemove(boardId) });
    });
  } catch (error) {
    logError(`error with deleting board ${error}`);
  }
};

export const unfollowBoard = async (uid: string, boardId: string) => {
  refToUserReflections(uid).update({ boards: firebase.firestore.FieldValue.arrayRemove(boardId) });
};

export const boardStream = (boardId: string): Observable<Board> => {
  return docData<Board>(refToReflectionBoard(boardId));
};

export const boardsStream = (boardIds: string[]): Observable<Board[]> => {
  const streams = boardIds.map(boardId => {
    return docData<Board>(refToReflectionBoard(boardId)).pipe(catchError(error => {
      logError(`Error fetching board ${boardId} ${error}`);
      return of(null);
    }))
  });
  return combineLatest(streams).pipe(map((boards) => boards.filter(b => Boolean(b)) as Board[]));
};

export const boardPromptsStream = (boardId: string): Observable<Prompt[]> => {
  return collectionData<Prompt>(refToReflectionBoardPrompts(boardId));
};

export const boardAnswersStreamByDate = (boardId: string, conceptualDate: string): Observable<Answer[]> => {
  return collectionData<Answer>(refToReflectionBoardAnswers(boardId).where("conceptual_date", "==", conceptualDate));
};

export const boardAnswersStreamByLatest = (boardId: string): Observable<Answer[]> => {
  return collectionData<Answer>(refToReflectionBoardAnswers(boardId).where("latest", "==", true));
};

export const boardAnswersStreamByPrompt = (boardId: string, promptId: string): Observable<Answer[]> => {
  return collectionData<Answer>(refToReflectionBoardAnswers(boardId).where("prompt_id", "==", promptId));
};

export const boardLatestAnswerForPromptQuery = (boardId: string, promptId: string): firebase.firestore.Query<Answer> => {
  return refToReflectionBoardAnswers(boardId).where("prompt_id", "==", promptId).where("empty", "==", false).orderBy("conceptual_date", "desc").limit(1);
};

export const boardAnswerForPromptInPeriodQuery = (boardId: string, promptId: string, startConceptualDate: string, endConceptualDate: string): firebase.firestore.Query<Answer> => {
  return refToReflectionBoardAnswers(boardId).where("prompt_id", "==", promptId).where("empty", "==", false)
    .where("conceptual_date", ">=", startConceptualDate).where("conceptual_date", "<=", endConceptualDate).orderBy("conceptual_date", "desc").limit(1);
};

export const boardLatestAnswersForWeeklyAndMonthlyPromptsStream = (boardId: string, prompts: Prompt[], conceptualDate: string): Observable<Answer[]> => {
  const streams = prompts.map((prompt) => {
    if (!prompt.id) {
      return of(null);
    }

    const date = parseConceptualDate(conceptualDate);
    let startOfPeriod: Date;
    switch (prompt.lifecycle) {
      case PromptLifecycle.Weekly:
        startOfPeriod = startOfWeek(date);
        break;
      case PromptLifecycle.Monthly:
        startOfPeriod = startOfMonth(date);
        break;
      case PromptLifecycle.WeeklyBurn:
        startOfPeriod = sub(new Date(), { days: 7 });
        break;
      case PromptLifecycle.DailyBurn:
        startOfPeriod = sub(new Date(), { days: 1 });
        break;
      default:
        startOfPeriod = startOfDay(date);
    }

    let endOfPeriod: Date;
    switch (prompt.lifecycle) {
      case PromptLifecycle.Weekly:
        endOfPeriod = endOfWeek(date);
        break;
      case PromptLifecycle.Monthly:
        endOfPeriod = endOfMonth(date);
        break;
      case PromptLifecycle.WeeklyBurn:
        endOfPeriod = new Date();
        break;
      case PromptLifecycle.DailyBurn:
        endOfPeriod = new Date();
        break;
      default:
        endOfPeriod = endOfDay(date);
    }

    return collectionData<Answer[]>(boardAnswerForPromptInPeriodQuery(boardId, prompt.id, formatConceptualDate(startOfPeriod), formatConceptualDate(endOfPeriod)))
      .pipe(map(answers => answers && answers.length > 0 ? answers[0] : null))
      .pipe(catchError(error => {
        logError(error); return of(null);
      }))
  });
  return combineLatest(streams).pipe(map((answers) => answers.filter(a => Boolean(a)) as Answer[]));
}

export const boardLatestAnswersForManualPromptsStream = (boardId: string, promptIds: string[]): Observable<Answer[]> => {
  const streams = promptIds.map((promptId) => {
    return collectionData<Answer[]>(boardLatestAnswerForPromptQuery(boardId, promptId))
      .pipe(map(answers => answers && answers.length > 0 ? answers[0] : null))
      .pipe(catchError(error => {
        logError(error); return of(null);
      }))
  });
  return combineLatest(streams).pipe(map((answers) => answers.filter(a => Boolean(a)) as Answer[]));
};

export const boardLatestAnswerForPromptBeforeDateQuery = (boardId: string, promptId: string, conceptual_date: string): firebase.firestore.Query<Answer> => {
  return boardLatestAnswerForPromptQuery(boardId, promptId).where("conceptual_date", "<=", conceptual_date);
};

export const boardJournalQuery = (boardId: string, size: number, startAfter: firebase.firestore.QueryDocumentSnapshot | null): firebase.firestore.Query<Answer> => {
  const query = refToReflectionBoardAnswers(boardId).orderBy("conceptual_date", "desc").orderBy("created_at", "desc").limit(size);
  if (startAfter) {
    return query.startAfter(startAfter);
  }
  return query;
};

export const boardJournalQueryByPrompts = (boardId: string, promptIds: string[], size: number, startAfter: firebase.firestore.QueryDocumentSnapshot | null): firebase.firestore.Query<Answer> => {
  return boardJournalQuery(boardId, size, startAfter).where("prompt_id", "in", promptIds);
};

export const boardInvitesQuery = (boardId: string): firebase.firestore.Query<Invite> => {
  return refToReflectionBoardInvites(boardId).where("expireAt", ">", new Date().valueOf()).where("cancelled", "==", false).where("used", "==", false);
};

export const boardInvitesStream = (boardId: string): Observable<Invite[]> => {
  return collectionData<Invite>(boardInvitesQuery(boardId));
};

// legacy
export const refToLegacyPrompts = (uid: string): firebase.firestore.CollectionReference<Prompt> => {
  return db.collection("mindful_reflections").doc(uid).collection("prompts").withConverter(promptConverter);
};

export const refToLegacyAnswers = (uid: string): firebase.firestore.CollectionReference<Answer> => {
  return db.collection("mindful_reflections").doc(uid).collection("answers").withConverter(answerConverter);
};

export const refToLegacyAnswerVersions = (uid: string, answerId: string): firebase.firestore.CollectionReference<Answer> => {
  return db.collection("mindful_reflections").doc(uid).collection("answers").doc(answerId).collection("versions").withConverter(answerConverter);
};
// legacy

export const refToCheckoutSessions = (uid: string): firebase.firestore.CollectionReference => {
  return db.collection("customers").doc(uid).collection("checkout_sessions");
}

export const getPrices = (): Promise<Product[]> => {
  return new Promise((resolve, reject) => {
    const products: Product[] = [];
    db.collection("products")
      .where("active", "==", true)
      .get()
      .then(async (querySnapshot) => {
        await Promise.all(querySnapshot.docs.map(async function (doc) {
          const product = Convert.toProduct(JSON.stringify(doc.data()));
          const priceSnap = await doc.ref.collection("prices").where("active", "==", true).get();
          const prices: { [key: string]: Price } = {};
          await Promise.all(priceSnap.docs.map(async (doc) => {
            const price = Convert.toPrice(JSON.stringify(doc.data()));
            prices[doc.id] = price;
          }));
          product.prices = prices;
          products.push(product);
        }));

        resolve(products);
      }).catch(reject);
  });
}

export const userSubscriptionStream = (uid: string): Observable<Subscription[]> => {
  const query: firebase.firestore.Query<Subscription> = db.collection('customers')
    .doc(uid)
    .collection('subscriptions')
    .withConverter({
      toFirestore(sub: Subscription): firebase.firestore.DocumentData {
        const safeData = JSON.parse(Convert.subscriptionToJson(sub));
        if (!safeData.created_at) {
          safeData.created_at = serverTimestamp();
        }
        safeData.updated_at = serverTimestamp();
        return safeData;
      },
      fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ): Subscription {
        const data = JSON.stringify(snapshot.data(options));
        const result = Convert.toSubscription(data);
        return result
      }
    })
    .where('status', 'in', ['trialing', 'active']);

  const stream = collectionData<Subscription>(query);
  return stream.pipe(catchError(error => {
    logError(`Error streaming subscriptions ${error}`);
    return of([] as Subscription[]);
  }));
}

export const userPaymentStream = (uid: string): Observable<Payment[]> => {
  return collectionData<Payment>(db.collection('customers')
    .doc(uid)
    .collection('payments')
    .withConverter({
      toFirestore(sub: Payment): firebase.firestore.DocumentData {
        const safeData = JSON.parse(Convert.paymentToJson(sub));
        if (!safeData.created_at) {
          safeData.created_at = serverTimestamp();
        }
        safeData.updated_at = serverTimestamp();
        return safeData;
      },
      fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
      ): Payment {
        const data = JSON.stringify(snapshot.data(options));
        const result = Convert.toPayment(data);
        return result
      }
    })
    .where('status', '==', 'succeeded'))
    .pipe(catchError(error => {
      logError(`Error streaming payments ${error}`);
      return of([] as Payment[]);
    }));
}

export default app;
