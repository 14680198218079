import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reflectionReducer from './ReflectionReducer';
import profileReducer from "./ProfileReducer";
import answerReducer from './AnswerReducer';
import journalReducer from './JournalReducer';
import planReducer from './PlanReducer';

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    reflections: reflectionReducer,
    answers: answerReducer,
    journal: journalReducer,
    plan: planReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
