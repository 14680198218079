import React, { useEffect, useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import firebase from "firebase/app";
import { authState } from "rxfire/auth";
import app from "../db";

import "./SignIn.sass";
import { logError, logInfo } from "../Utils";

async function loginWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  provider.addScope("email");

  try {
    const result = await firebase.auth().signInWithPopup(provider);
    if (result.user) {
      navigate("/app");
    } else {
      logInfo("Failed to get user from Google SignIn");
    }
  } catch (error) {
    logError(error);
  }
}

function SignIn(_: RouteComponentProps) {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    authState(app.auth()).subscribe((u) => {
      setLoading(false);
      if (u) {
        navigate("/app");
      }
    });
  });

  return (
    <div className="App">
      {!loading && (
        <div className="SignIn">
          <div onClick={loginWithGoogle}>
            <img src={`${process.env.PUBLIC_URL}/btn_google_signin_dark_pressed_web@2x.png`} alt="Login with Google" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SignIn;
