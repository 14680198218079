import React, { MouseEvent, useState } from "react";
import firebase from "firebase/app";
import "firebase/functions";
import { navigate, RouteComponentProps } from "@reach/router";

import Button from "@material-ui/core/Button";
import { BrightnessController, BrightnessControllerConsumer, logError, UserConsumer } from "./Utils";
import "./Settings.sass";
import { IconButton } from "@material-ui/core";
import SubscriptionDialog from "./Subscription";
import WbSunny from "@material-ui/icons/WbSunny";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { isPro, isTeam, selectPlan } from "./store/PlanReducer";
import app from "./db";

const GET_PORTAL_URL_FUNCTION_NAME =
  process.env.NODE_ENV === "production"
    ? "ext-firestore-stripe-subscriptions-createPortalLink"
    : "ext-firestore-stripe-payments-5sqg-createPortalLink";

function Settings(props: {
  user?: firebase.User | null;
  boardId?: string | null;
  controller: BrightnessController | null;
}) {
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const plan = useSelector(selectPlan);
  const subscribed = isPro(plan);
  const isOnTeam = isTeam(plan);

  function onDone(event: MouseEvent<HTMLElement>) {
    navigate(`/app`);
  }

  function onToggleBrightness() {
    props.controller?.setDark(!(props.controller?.dark ?? false));
  }

  function logOut() {
    firebase.auth().signOut();
    navigate("/");
  }

  function onUpgrade() {
    setOpenSubscriptionModal(true);
  }

  async function manageSubscription() {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const functionRef = app.functions("us-central1").httpsCallable(GET_PORTAL_URL_FUNCTION_NAME);

      const { data } = await functionRef({
        returnUrl: window.location.toString(),
      });

      window.location.assign(data.url);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={`CanvasWrapper ${props.controller?.dark ?? false ? "Dark" : ""}`}>
      <div className="CanvasMenu">
        <div className="CanvasTitle">
          <img
            className="logo"
            src={`${process.env.PUBLIC_URL}/${props.controller?.dark ? "logo-dark.svg" : "logo.svg"}`}
            alt="Jot"
          />
          <div onClick={onToggleBrightness}>
            <IconButton aria-label="brightness" size="small">
              {props.controller?.dark ?? true ? <Icon icon="akar-icons:moon-fill" /> : <WbSunny />}
            </IconButton>
          </div>
        </div>
        <div className="CanvasControls">
          <Button aria-label="exit" size="small" variant="contained" color="primary" disableElevation onClick={onDone}>
            Done
          </Button>
        </div>
      </div>
      <div className="Settings">
        <div className="CenterWrapper">
          <div className="Account">
            <h2>Account</h2>
            <div className="Row">
              {props.user?.displayName}
              <Button color="secondary" variant="contained" disableElevation onClick={logOut}>
                Logout
              </Button>
            </div>
          </div>
          <div className="Subscription">
            <h2>Subscription</h2>
            {isOnTeam && (
              <div className="Row">
                Team plan
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={manageSubscription}
                  disabled={loading}
                >
                  Manage subscription
                </Button>
              </div>
            )}
            {!isOnTeam && subscribed && (
              <div className="Row">
                Pro
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={manageSubscription}
                  disabled={loading}
                >
                  Manage subscription
                </Button>
              </div>
            )}
            {!isOnTeam && !subscribed && (
              <div className="Row">
                Free
                <Button color="primary" variant="contained" disableElevation onClick={onUpgrade}>
                  Subscribe
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.user && (
        <SubscriptionDialog
          open={openSubscriptionModal}
          uid={props.user.uid}
          onClose={() => setOpenSubscriptionModal(false)}
        />
      )}
    </div>
  );
}

function SettingsWithUser({ board }: { board?: string | null } & RouteComponentProps) {
  return (
    <UserConsumer>
      {(user) => {
        return (
          <BrightnessControllerConsumer>
            {(controller) => {
              return <Settings user={user} boardId={board} controller={controller} />;
            }}
          </BrightnessControllerConsumer>
        );
      }}
    </UserConsumer>
  );
}

export default SettingsWithUser;
