import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Payment, Product, Subscription } from '../Models';
import { RootState } from './Store';

interface Plan {
  products: Product[];
  subscriptions: Subscription[];
  payments: Payment[];
}

const initialState: Plan = { products: [], subscriptions: [], payments: [] } as Plan;

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    loadProducts: (current, action: PayloadAction<Product[]>) => {
      current.products = action.payload;
      return current;
    },
    initializeSubscriptions: (current, action: PayloadAction<Subscription[]>) => {
      current.subscriptions = action.payload;
      return current;
    },
    initializePayments: (current, action: PayloadAction<Payment[]>) => {
      current.payments = action.payload;
      return current;
    },
  },
});

export const { loadProducts, initializePayments, initializeSubscriptions } = planSlice.actions;

export const selectPlan = (state: RootState) => state.plan;

export function isPro(plan: Plan): boolean {
  return Boolean(plan.subscriptions.find((s) => s.status === "active")) ||
    Boolean(plan.payments.find((p) => p.status === "succeeded"));
}

export function isTeam(plan: Plan): boolean {
  return Boolean(plan.subscriptions.find((s) => {
    const price = (s.items ?? [])[0]?.price;
    return s.status === "active" && (price?.name ?? "").toLowerCase().indexOf("team") >= 0;
  })) ||
    Boolean(plan.payments.find((p) => {
      const item = (p.items ?? [])[0];
      return p.status === "succeeded" && (item?.description ?? "").toLowerCase().indexOf("team") >= 0;
    }));
}

export default planSlice.reducer;

