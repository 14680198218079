import { Divider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { Board } from "../Models";

export const NEW_BOARD_VALUE = "CREATE_NEW_BOARD";

export default function BoardSelector({
  board,
  boards,
  selectedBoardId,
  onChangeBoard,
  showNew,
}: {
  board: Board | null | undefined;
  boards: Board[] | null | undefined;
  selectedBoardId: string;
  onChangeBoard: (boardId: string) => void;
  showNew?: boolean | null;
}) {
  function onChange(event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) {
    const value = event.target.value as string;
    onChangeBoard(value);
  }

  return (
    <Select
      className="BoardSelect"
      value={selectedBoardId}
      onChange={onChange}
      displayEmpty
      inputProps={{ "aria-label": "Boards" }}
      variant="filled"
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        getContentAnchorEl: null,
      }}
    >
      {showNew && <MenuItem value={NEW_BOARD_VALUE}>New board</MenuItem>}
      {showNew && <Divider />}
      {(!boards || boards.length === 0) && (
        <MenuItem value={selectedBoardId}>{board?.name ?? "Personal board"}</MenuItem>
      )}
      {boards &&
        boards.length > 0 &&
        boards.map((b, i) => (
          <MenuItem key={b.id ?? i} value={b.id ?? ""}>
            {b?.name ?? "Personal board"}
          </MenuItem>
        ))}
    </Select>
  );
}
