import React, {
  ChangeEvent,
  FocusEvent,
  createRef,
  KeyboardEvent,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { debounceTime, Subject } from "rxjs";
import { count } from "@wordpress/wordcount";
import { Answer, Prompt, PromptLifecycle } from "./Models";
import { saveAnswer, selectAnswers } from "./store/AnswerReducer";
import { countStreaks, getColor, getTextColor, lastestAnswer, timestampToMs } from "./Utils";
import { Icon } from "@iconify/react";
import color from "color";

export interface DirtyPromptValue {
  promptId: string;
  answerId?: string | null;
  dirtyValue: string;
}

export default function PromptView({
  prompt,
  conceptualDate,
  boardId,
  setFullscreenPrompt,
  isFullscreen,
  bgColor,
  dark,
  updateDirtyValue,
  uid,
}: {
  prompt?: Prompt;
  conceptualDate: string;
  boardId: string;
  setFullscreenPrompt: (promptId: string) => void;
  isFullscreen: boolean;
  bgColor?: string | undefined;
  dark?: boolean;
  updateDirtyValue: (dirtyValue: DirtyPromptValue | null) => void;
  uid?: string | null;
}) {
  const dispatch = useDispatch();
  const [onChange$, setOnChange] = useState<Subject<string>>();
  const [focused, setFocused] = useState<boolean>(false);
  const { answersByPromptId } = useSelector(selectAnswers);
  const answer: Answer | null | undefined = lastestAnswer(
    prompt ? answersByPromptId[prompt.id!] : []
  );
  const answerText = prompt ? answer?.text ?? "" : "";
  const dirtyValueTimestamp = useRef<number>(timestampToMs(answer?.created_at));
  const ref = createRef<HTMLTextAreaElement>();

  const h3Style = {
    color: getTextColor(dark, bgColor)
    // borderBottom: `1px solid ${color(bgColor).darken(0.3333).desaturate(0.5).rotate(20).alpha(0.2).string()}`,
  };

  useEffect(() => {
    if (!onChange$) {
      setOnChange(new Subject<string>());
      return;
    }

    const changeSubscription = onChange$
      .pipe(debounceTime(isFullscreen ? 1000 : 3000))
      .subscribe((text) => {
        if (prompt && uid) {
          dispatch(
            saveAnswer(boardId, prompt, conceptualDate, answer?.id, uid, text)
          );
        }
      });

    return () => {
      changeSubscription.unsubscribe();
    };
  }, [
    conceptualDate,
    dispatch,
    onChange$,
    prompt,
    boardId,
    updateDirtyValue,
    answer?.id,
    uid,
    isFullscreen,
  ]);

  function onTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
    dirtyValueTimestamp.current = new Date().valueOf();
    if (prompt?.id) {
      updateDirtyValue({
        promptId: prompt.id,
        dirtyValue: event.currentTarget.value,
        answer: answer?.id,
      } as DirtyPromptValue);
    }
    onChange$?.next(event.currentTarget.value);
  }

  function saveDirtyValue(event: FocusEvent<HTMLTextAreaElement>) {
    const currentValue = event.currentTarget.value;
    if (prompt && currentValue !== answerText && uid) {
      dispatch(
        saveAnswer(
          boardId,
          prompt,
          conceptualDate,
          answer?.id,
          uid,
          currentValue
        )
      );
      updateDirtyValue(null);
      dirtyValueTimestamp.current = timestampToMs(answer?.created_at);
    }
  }

  function onKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (!prompt) {
      return;
    }

    if (event.code === "Enter" && event.metaKey) {
      event.currentTarget.blur();
      setTimeout(() => setFullscreenPrompt(prompt.id!), 100);
    } else if (event.code === "Escape") {
      event.currentTarget.blur();
      if (isFullscreen) {
        // give blur a chance to save dirty value.
        setTimeout(() => setFullscreenPrompt(prompt.id!), 100);
      }
    }
  }

  useEffect(() => {
    // update text if out of sync and answer version is newer.
    if (
      ref.current &&
      ref.current.value !== answerText &&
      timestampToMs(answer?.created_at) >= dirtyValueTimestamp.current
    ) {
      ref.current.value = answerText;
    }
  }, [isFullscreen, focused, ref, answerText, answer?.created_at]);

  useEffect(() => {
    if (isFullscreen && ref.current && !focused) {
      ref.current.focus();
      ref.current.setSelectionRange(
        ref.current.value.length,
        ref.current.value.length
      );
      setFocused(true);
    }
  }, [isFullscreen, focused, ref]);

  const defaultValue = answerText;

  const numberOfStreaks = countStreaks(
    prompt?.answers ?? [],
    conceptualDate,
    answerText
  );
  const numberOfWords = count(answerText, "words", {});
  const numberOfChars = count(answerText, "characters_excluding_spaces", {});

  return (
    <div
      className="Prompt"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }}
    >
      <h3 style={h3Style}>
        {prompt?.question}
        {prompt?.lifecycle === PromptLifecycle.Manual && (
          <Icon className="Pin" icon="carbon:pin-filled" />
        )}
      </h3>
      <TextareaAutosize
        ref={ref}
        // minRows={5}
        // maxRows={10}
        onChange={onTextChange}
        onBlur={saveDirtyValue}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
      ></TextareaAutosize>
      <div className="Stats">
        <span>
          <img src={`${process.env.PUBLIC_URL}/fire-icon.png`} alt="Streaks" />{" "}
          {numberOfStreaks}
        </span>
        <span>
          <img src={`${process.env.PUBLIC_URL}/words-icon.png`} alt="Words" />{" "}
          {numberOfWords}
        </span>
        <span>
          <img src={`${process.env.PUBLIC_URL}/char-icon.png`} alt="Chars" />{" "}
          {numberOfChars}
        </span>
      </div>
    </div>
  );
}

export function FetchingPromptView() {
  return (
    <div className="Prompt">
      <div className="Shimmer"></div>
    </div>
  );
}
