/* eslint:disable */

// To parse this data:
//
//   import { Convert, RoleType, BoardType, PromptLifecycle, PromptType, Timestamp, Answer, Prompt, Column, MindfulReflections, Product, Price, PaymentPrice, PaymentItem, SubscriptionPrice, SubscriptionItem, Subscription, Payment, BoardMemberProfile, BoardStyles, Board, MarketplaceBoard, Invite, GetInvitePayload, SendInvitePayload } from "./file";
//
//   const roleType = Convert.toRoleType(json);
//   const boardType = Convert.toBoardType(json);
//   const promptLifecycle = Convert.toPromptLifecycle(json);
//   const promptType = Convert.toPromptType(json);
//   const timestamp = Convert.toTimestamp(json);
//   const answer = Convert.toAnswer(json);
//   const prompt = Convert.toPrompt(json);
//   const column = Convert.toColumn(json);
//   const mindfulReflections = Convert.toMindfulReflections(json);
//   const product = Convert.toProduct(json);
//   const price = Convert.toPrice(json);
//   const paymentPrice = Convert.toPaymentPrice(json);
//   const paymentItem = Convert.toPaymentItem(json);
//   const subscriptionPrice = Convert.toSubscriptionPrice(json);
//   const subscriptionItem = Convert.toSubscriptionItem(json);
//   const subscription = Convert.toSubscription(json);
//   const payment = Convert.toPayment(json);
//   const boardMemberProfile = Convert.toBoardMemberProfile(json);
//   const boardStyles = Convert.toBoardStyles(json);
//   const board = Convert.toBoard(json);
//   const marketplaceBoard = Convert.toMarketplaceBoard(json);
//   const invite = Convert.toInvite(json);
//   const getInvitePayload = Convert.toGetInvitePayload(json);
//   const sendInvitePayload = Convert.toSendInvitePayload(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Answer {
    conceptual_date?: string;
    created_at?:      null | Timestamp;
    empty?:           boolean | null;
    id?:              string;
    prompt_id?:       string;
    prompt_question?: string;
    text?:            string;
    uid?:             null | string;
    updated_at?:      null | Timestamp;
}

export interface Timestamp {
    nanoseconds?: number;
    seconds?:     number;
}

export interface Prompt {
    answers?:                       string[] | null;
    created_at?:                    null | Timestamp;
    id?:                            string;
    latest_answer_conceptual_date?: null | string;
    latest_answer_id?:              null | string;
    lifecycle?:                     PromptLifecycle | null;
    question?:                      string;
    type?:                          PromptType | null;
    updated_at?:                    null | Timestamp;
}

export enum PromptLifecycle {
    Daily = "daily",
    DailyBurn = "daily-burn",
    Manual = "manual",
    Monthly = "monthly",
    Weekly = "weekly",
    WeeklyBurn = "weekly-burn",
}

export enum PromptType {
    Boolean = "boolean",
    Calendar = "calendar",
    Checklist = "checklist",
    Journal = "journal",
    Rating = "rating",
}

export interface MindfulReflections {
    boards?:         string[] | null;
    columns?:        Column[] | null;
    created_at?:     null | Timestamp;
    defaultBoardId?: null | string;
    initialized?:    boolean | null;
    updated_at?:     null | Timestamp;
    version?:        null | string;
}

export interface Column {
    color?:   string;
    id?:      string;
    name?:    string;
    prompts?: string[];
}

export interface Product {
    active?:      boolean;
    description?: null | string;
    name?:        null | string;
    prices?:      { [key: string]: Price };
}

export interface Price {
    active?:      boolean;
    currency?:    null | string;
    description?: null | string;
    interval?:    null | string;
    unit_amount?: number | null;
}

export interface Subscription {
    items?:      SubscriptionItem[] | null;
    status?:     null | string;
    stripeLink?: null | string;
}

export interface SubscriptionItem {
    price?: null | SubscriptionPrice;
}

export interface SubscriptionPrice {
    active?:      boolean;
    currency?:    null | string;
    name?:        null | string;
    unit_amount?: number | null;
}

export interface Payment {
    items?:  PaymentItem[] | null;
    status?: null | string;
}

export interface PaymentItem {
    description?: null | string;
    price?:       null | PaymentPrice;
}

export interface PaymentPrice {
    active?:      boolean;
    recurring?:   boolean | null;
    type?:        null | string;
    unit_amount?: number | null;
}

export interface Board {
    columns?:        Column[] | null;
    created_at?:     null | Timestamp;
    description?:    null | string;
    id?:             null | string;
    memberProfiles?: { [key: string]: BoardMemberProfile };
    members?:        { [key: string]: RoleType };
    name?:           null | string;
    published?:      boolean | null;
    styles?:         null | BoardStyles;
    type?:           BoardType;
    updated_at?:     null | Timestamp;
}

export interface BoardMemberProfile {
    email?:           null | string;
    name?:            null | string;
    profile_picture?: null | string;
}

export enum RoleType {
    Editor = "editor",
    Owner = "owner",
    Viewer = "viewer",
}

export interface BoardStyles {
    backgroundColor?: null | string;
    backgroundImage?: null | string;
    equalSize?:       boolean | null;
    fontStyle?:       null | string;
}

export enum BoardType {
    Daily = "daily",
    Form = "form",
    Monthly = "monthly",
    Static = "static",
    Template = "template",
}

export interface MarketplaceBoard {
    columns?:     Column[] | null;
    created_at?:  null | Timestamp;
    description?: null | string;
    name?:        null | string;
    type?:        BoardType;
    updated_at?:  null | Timestamp;
}

export interface Invite {
    boardId?:     null | string;
    boardName?:   null | string;
    cancelled?:   boolean | null;
    cancelledAt?: number | null;
    code?:        null | string;
    email?:       null | string;
    expireAt?:    number | null;
    id?:          null | string;
    initialRole?: RoleType | null;
    inviter?:     null | string;
    used?:        boolean | null;
    usedAt?:      number | null;
}

export interface GetInvitePayload {
    boardId?: null | string;
    code?:    null | string;
    id?:      null | string;
}

export interface SendInvitePayload {
    boardId?: null | string;
    email?:   null | string;
    role?:    RoleType;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toRoleType(json: string): RoleType {
        return cast(JSON.parse(json), r("RoleType"));
    }

    public static roleTypeToJson(value: RoleType): string {
        return JSON.stringify(uncast(value, r("RoleType")), null, 2);
    }

    public static toBoardType(json: string): BoardType {
        return cast(JSON.parse(json), r("BoardType"));
    }

    public static boardTypeToJson(value: BoardType): string {
        return JSON.stringify(uncast(value, r("BoardType")), null, 2);
    }

    public static toPromptLifecycle(json: string): PromptLifecycle {
        return cast(JSON.parse(json), r("PromptLifecycle"));
    }

    public static promptLifecycleToJson(value: PromptLifecycle): string {
        return JSON.stringify(uncast(value, r("PromptLifecycle")), null, 2);
    }

    public static toPromptType(json: string): PromptType {
        return cast(JSON.parse(json), r("PromptType"));
    }

    public static promptTypeToJson(value: PromptType): string {
        return JSON.stringify(uncast(value, r("PromptType")), null, 2);
    }

    public static toTimestamp(json: string): Timestamp {
        return cast(JSON.parse(json), r("Timestamp"));
    }

    public static timestampToJson(value: Timestamp): string {
        return JSON.stringify(uncast(value, r("Timestamp")), null, 2);
    }

    public static toAnswer(json: string): Answer {
        return cast(JSON.parse(json), r("Answer"));
    }

    public static answerToJson(value: Answer): string {
        return JSON.stringify(uncast(value, r("Answer")), null, 2);
    }

    public static toPrompt(json: string): Prompt {
        return cast(JSON.parse(json), r("Prompt"));
    }

    public static promptToJson(value: Prompt): string {
        return JSON.stringify(uncast(value, r("Prompt")), null, 2);
    }

    public static toColumn(json: string): Column {
        return cast(JSON.parse(json), r("Column"));
    }

    public static columnToJson(value: Column): string {
        return JSON.stringify(uncast(value, r("Column")), null, 2);
    }

    public static toMindfulReflections(json: string): MindfulReflections {
        return cast(JSON.parse(json), r("MindfulReflections"));
    }

    public static mindfulReflectionsToJson(value: MindfulReflections): string {
        return JSON.stringify(uncast(value, r("MindfulReflections")), null, 2);
    }

    public static toProduct(json: string): Product {
        return cast(JSON.parse(json), r("Product"));
    }

    public static productToJson(value: Product): string {
        return JSON.stringify(uncast(value, r("Product")), null, 2);
    }

    public static toPrice(json: string): Price {
        return cast(JSON.parse(json), r("Price"));
    }

    public static priceToJson(value: Price): string {
        return JSON.stringify(uncast(value, r("Price")), null, 2);
    }

    public static toPaymentPrice(json: string): PaymentPrice {
        return cast(JSON.parse(json), r("PaymentPrice"));
    }

    public static paymentPriceToJson(value: PaymentPrice): string {
        return JSON.stringify(uncast(value, r("PaymentPrice")), null, 2);
    }

    public static toPaymentItem(json: string): PaymentItem {
        return cast(JSON.parse(json), r("PaymentItem"));
    }

    public static paymentItemToJson(value: PaymentItem): string {
        return JSON.stringify(uncast(value, r("PaymentItem")), null, 2);
    }

    public static toSubscriptionPrice(json: string): SubscriptionPrice {
        return cast(JSON.parse(json), r("SubscriptionPrice"));
    }

    public static subscriptionPriceToJson(value: SubscriptionPrice): string {
        return JSON.stringify(uncast(value, r("SubscriptionPrice")), null, 2);
    }

    public static toSubscriptionItem(json: string): SubscriptionItem {
        return cast(JSON.parse(json), r("SubscriptionItem"));
    }

    public static subscriptionItemToJson(value: SubscriptionItem): string {
        return JSON.stringify(uncast(value, r("SubscriptionItem")), null, 2);
    }

    public static toSubscription(json: string): Subscription {
        return cast(JSON.parse(json), r("Subscription"));
    }

    public static subscriptionToJson(value: Subscription): string {
        return JSON.stringify(uncast(value, r("Subscription")), null, 2);
    }

    public static toPayment(json: string): Payment {
        return cast(JSON.parse(json), r("Payment"));
    }

    public static paymentToJson(value: Payment): string {
        return JSON.stringify(uncast(value, r("Payment")), null, 2);
    }

    public static toBoardMemberProfile(json: string): BoardMemberProfile {
        return cast(JSON.parse(json), r("BoardMemberProfile"));
    }

    public static boardMemberProfileToJson(value: BoardMemberProfile): string {
        return JSON.stringify(uncast(value, r("BoardMemberProfile")), null, 2);
    }

    public static toBoardStyles(json: string): BoardStyles {
        return cast(JSON.parse(json), r("BoardStyles"));
    }

    public static boardStylesToJson(value: BoardStyles): string {
        return JSON.stringify(uncast(value, r("BoardStyles")), null, 2);
    }

    public static toBoard(json: string): Board {
        return cast(JSON.parse(json), r("Board"));
    }

    public static boardToJson(value: Board): string {
        return JSON.stringify(uncast(value, r("Board")), null, 2);
    }

    public static toMarketplaceBoard(json: string): MarketplaceBoard {
        return cast(JSON.parse(json), r("MarketplaceBoard"));
    }

    public static marketplaceBoardToJson(value: MarketplaceBoard): string {
        return JSON.stringify(uncast(value, r("MarketplaceBoard")), null, 2);
    }

    public static toInvite(json: string): Invite {
        return cast(JSON.parse(json), r("Invite"));
    }

    public static inviteToJson(value: Invite): string {
        return JSON.stringify(uncast(value, r("Invite")), null, 2);
    }

    public static toGetInvitePayload(json: string): GetInvitePayload {
        return cast(JSON.parse(json), r("GetInvitePayload"));
    }

    public static getInvitePayloadToJson(value: GetInvitePayload): string {
        return JSON.stringify(uncast(value, r("GetInvitePayload")), null, 2);
    }

    public static toSendInvitePayload(json: string): SendInvitePayload {
        return cast(JSON.parse(json), r("SendInvitePayload"));
    }

    public static sendInvitePayloadToJson(value: SendInvitePayload): string {
        return JSON.stringify(uncast(value, r("SendInvitePayload")), null, 2);
    }
}

function invalidValue(typ: any, val: any): never {
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        var map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        var l = typs.length;
        for (var i = 0; i < l; i++) {
            var typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(typ: any, val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        var result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) return invalidValue(typ, val);
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(typ, val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "Answer": o([
        { json: "conceptual_date", js: "conceptual_date", typ: u(undefined, "") },
        { json: "created_at", js: "created_at", typ: u(undefined, u(null, r("Timestamp"))) },
        { json: "empty", js: "empty", typ: u(undefined, u(true, null)) },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "prompt_id", js: "prompt_id", typ: u(undefined, "") },
        { json: "prompt_question", js: "prompt_question", typ: u(undefined, "") },
        { json: "text", js: "text", typ: u(undefined, "") },
        { json: "uid", js: "uid", typ: u(undefined, u(null, "")) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(null, r("Timestamp"))) },
    ], "any"),
    "Timestamp": o([
        { json: "nanoseconds", js: "nanoseconds", typ: u(undefined, 3.14) },
        { json: "seconds", js: "seconds", typ: u(undefined, 3.14) },
    ], "any"),
    "Prompt": o([
        { json: "answers", js: "answers", typ: u(undefined, u(a(""), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(null, r("Timestamp"))) },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "latest_answer_conceptual_date", js: "latest_answer_conceptual_date", typ: u(undefined, u(null, "")) },
        { json: "latest_answer_id", js: "latest_answer_id", typ: u(undefined, u(null, "")) },
        { json: "lifecycle", js: "lifecycle", typ: u(undefined, u(r("PromptLifecycle"), null)) },
        { json: "question", js: "question", typ: u(undefined, "") },
        { json: "type", js: "type", typ: u(undefined, u(r("PromptType"), null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(null, r("Timestamp"))) },
    ], "any"),
    "MindfulReflections": o([
        { json: "boards", js: "boards", typ: u(undefined, u(a(""), null)) },
        { json: "columns", js: "columns", typ: u(undefined, u(a(r("Column")), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(null, r("Timestamp"))) },
        { json: "defaultBoardId", js: "defaultBoardId", typ: u(undefined, u(null, "")) },
        { json: "initialized", js: "initialized", typ: u(undefined, u(true, null)) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(null, r("Timestamp"))) },
        { json: "version", js: "version", typ: u(undefined, u(null, "")) },
    ], "any"),
    "Column": o([
        { json: "color", js: "color", typ: u(undefined, "") },
        { json: "id", js: "id", typ: u(undefined, "") },
        { json: "name", js: "name", typ: u(undefined, "") },
        { json: "prompts", js: "prompts", typ: u(undefined, a("")) },
    ], "any"),
    "Product": o([
        { json: "active", js: "active", typ: u(undefined, true) },
        { json: "description", js: "description", typ: u(undefined, u(null, "")) },
        { json: "name", js: "name", typ: u(undefined, u(null, "")) },
        { json: "prices", js: "prices", typ: u(undefined, m(r("Price"))) },
    ], "any"),
    "Price": o([
        { json: "active", js: "active", typ: u(undefined, true) },
        { json: "currency", js: "currency", typ: u(undefined, u(null, "")) },
        { json: "description", js: "description", typ: u(undefined, u(null, "")) },
        { json: "interval", js: "interval", typ: u(undefined, u(null, "")) },
        { json: "unit_amount", js: "unit_amount", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "Subscription": o([
        { json: "items", js: "items", typ: u(undefined, u(a(r("SubscriptionItem")), null)) },
        { json: "status", js: "status", typ: u(undefined, u(null, "")) },
        { json: "stripeLink", js: "stripeLink", typ: u(undefined, u(null, "")) },
    ], "any"),
    "SubscriptionItem": o([
        { json: "price", js: "price", typ: u(undefined, u(null, r("SubscriptionPrice"))) },
    ], "any"),
    "SubscriptionPrice": o([
        { json: "active", js: "active", typ: u(undefined, true) },
        { json: "currency", js: "currency", typ: u(undefined, u(null, "")) },
        { json: "name", js: "name", typ: u(undefined, u(null, "")) },
        { json: "unit_amount", js: "unit_amount", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "Payment": o([
        { json: "items", js: "items", typ: u(undefined, u(a(r("PaymentItem")), null)) },
        { json: "status", js: "status", typ: u(undefined, u(null, "")) },
    ], "any"),
    "PaymentItem": o([
        { json: "description", js: "description", typ: u(undefined, u(null, "")) },
        { json: "price", js: "price", typ: u(undefined, u(null, r("PaymentPrice"))) },
    ], "any"),
    "PaymentPrice": o([
        { json: "active", js: "active", typ: u(undefined, true) },
        { json: "recurring", js: "recurring", typ: u(undefined, u(true, null)) },
        { json: "type", js: "type", typ: u(undefined, u(null, "")) },
        { json: "unit_amount", js: "unit_amount", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "Board": o([
        { json: "columns", js: "columns", typ: u(undefined, u(a(r("Column")), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(null, r("Timestamp"))) },
        { json: "description", js: "description", typ: u(undefined, u(null, "")) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "memberProfiles", js: "memberProfiles", typ: u(undefined, m(r("BoardMemberProfile"))) },
        { json: "members", js: "members", typ: u(undefined, m(r("RoleType"))) },
        { json: "name", js: "name", typ: u(undefined, u(null, "")) },
        { json: "published", js: "published", typ: u(undefined, u(true, null)) },
        { json: "styles", js: "styles", typ: u(undefined, u(null, r("BoardStyles"))) },
        { json: "type", js: "type", typ: u(undefined, r("BoardType")) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(null, r("Timestamp"))) },
    ], "any"),
    "BoardMemberProfile": o([
        { json: "email", js: "email", typ: u(undefined, u(null, "")) },
        { json: "name", js: "name", typ: u(undefined, u(null, "")) },
        { json: "profile_picture", js: "profile_picture", typ: u(undefined, u(null, "")) },
    ], "any"),
    "BoardStyles": o([
        { json: "backgroundColor", js: "backgroundColor", typ: u(undefined, u(null, "")) },
        { json: "backgroundImage", js: "backgroundImage", typ: u(undefined, u(null, "")) },
        { json: "equalSize", js: "equalSize", typ: u(undefined, u(true, null)) },
        { json: "fontStyle", js: "fontStyle", typ: u(undefined, u(null, "")) },
    ], "any"),
    "MarketplaceBoard": o([
        { json: "columns", js: "columns", typ: u(undefined, u(a(r("Column")), null)) },
        { json: "created_at", js: "created_at", typ: u(undefined, u(null, r("Timestamp"))) },
        { json: "description", js: "description", typ: u(undefined, u(null, "")) },
        { json: "name", js: "name", typ: u(undefined, u(null, "")) },
        { json: "type", js: "type", typ: u(undefined, r("BoardType")) },
        { json: "updated_at", js: "updated_at", typ: u(undefined, u(null, r("Timestamp"))) },
    ], "any"),
    "Invite": o([
        { json: "boardId", js: "boardId", typ: u(undefined, u(null, "")) },
        { json: "boardName", js: "boardName", typ: u(undefined, u(null, "")) },
        { json: "cancelled", js: "cancelled", typ: u(undefined, u(true, null)) },
        { json: "cancelledAt", js: "cancelledAt", typ: u(undefined, u(3.14, null)) },
        { json: "code", js: "code", typ: u(undefined, u(null, "")) },
        { json: "email", js: "email", typ: u(undefined, u(null, "")) },
        { json: "expireAt", js: "expireAt", typ: u(undefined, u(3.14, null)) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
        { json: "initialRole", js: "initialRole", typ: u(undefined, u(r("RoleType"), null)) },
        { json: "inviter", js: "inviter", typ: u(undefined, u(null, "")) },
        { json: "used", js: "used", typ: u(undefined, u(true, null)) },
        { json: "usedAt", js: "usedAt", typ: u(undefined, u(3.14, null)) },
    ], "any"),
    "GetInvitePayload": o([
        { json: "boardId", js: "boardId", typ: u(undefined, u(null, "")) },
        { json: "code", js: "code", typ: u(undefined, u(null, "")) },
        { json: "id", js: "id", typ: u(undefined, u(null, "")) },
    ], "any"),
    "SendInvitePayload": o([
        { json: "boardId", js: "boardId", typ: u(undefined, u(null, "")) },
        { json: "email", js: "email", typ: u(undefined, u(null, "")) },
        { json: "role", js: "role", typ: u(undefined, r("RoleType")) },
    ], "any"),
    "PromptLifecycle": [
        "daily",
        "daily-burn",
        "manual",
        "monthly",
        "weekly",
        "weekly-burn",
    ],
    "PromptType": [
        "boolean",
        "calendar",
        "checklist",
        "journal",
        "rating",
    ],
    "RoleType": [
        "editor",
        "owner",
        "viewer",
    ],
    "BoardType": [
        "daily",
        "form",
        "monthly",
        "static",
        "template",
    ],
};
