import React, { createRef, MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WbSunny from "@material-ui/icons/WbSunny";
import { debounceTime, Subject } from "rxjs";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import firebase from "firebase/app";
import {
  BrightnessController,
  BrightnessControllerConsumer,
  conceptualToDisplayDay,
  conceptualToDisplayMonth,
  conceptualToDisplayWeekday,
  conceptualToDisplayYearMonth,
  getColor,
  getTextColor,
  logError,
  logInfo,
  patchAnswerEmptyField,
  UserConsumer,
} from "./Utils";
import { Answer, Column, Prompt } from "./Models";
import "./Journal.sass";
import { changeBoard, selectReflections } from "./store/ReflectionReducer";
import { boardJournalQuery, boardJournalQueryByPrompts } from "./db";
import { changePromptIds, invalidateJournal, Section, selectJournal, updateAnswers } from "./store/JournalReducer";
import BoardSelector, { NEW_BOARD_VALUE } from "./components/BoardSelector";
import NewBoardDialog from "./components/NewBoardDialog";
import BoardSettings from "./components/BoardSettings";
import { Icon } from "@iconify/react";
import SubscriptionDialog from "./Subscription";
import { isPro, selectPlan } from "./store/PlanReducer";

const PageSize = 20;

function JournalContent({
  hasNextPage,
  sections,
  // itemCount,
  setRowHeight,
  getRowHeight,
  singleTopic,
  prompts,
  listRef,
  isItemLoaded,
  dark,
}: {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  sections: Section[];
  // itemCount: number;
  setRowHeight: (index: number, size: number) => void;
  getRowHeight: (index: number) => number;
  singleTopic: boolean;
  prompts?: { [key: string]: Prompt };
  listRef: React.MutableRefObject<any>;
  isItemLoaded: (index: number) => boolean;
  dark?: boolean | null;
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? sections.length + 1 : sections.length;

  // Don't fucking wait for react window to load it. It caches the range so doesn't trigger.
  const loadMoreItems = () => {};

  // Render an item or a loading indicator.
  function Row({ index, style }: { index: number; style: React.CSSProperties }) {
    const rowRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, rowRef]);

    if (index > sections.length - 1) {
      return (
        <div style={style}>
          <div ref={rowRef} className="Empty" key={`fetching/${index}`}></div>
        </div>
      );
    }

    const section = sections[index];

    return (
      <div style={style}>
        <div
          ref={rowRef}
          className={section.isMonth ? "Section-Title" : "Section"}
          key={section.isMonth ? `section-title/${section.date}` : `section/${section.date}`}
        >
          <div className="Section-Bg">
            <div className="Section-Header">
              {section.isMonth ? (
                <h2 className="Section-Title-Month">{conceptualToDisplayYearMonth(section.date)}</h2>
              ) : (
                <h2>
                  <div className="Section-Date-Weekday">{conceptualToDisplayWeekday(section.date)}</div>
                  <div>{conceptualToDisplayMonth(section.date)}</div>
                  <div className="Section-Date-Day">{conceptualToDisplayDay(section.date)}</div>
                </h2>
              )}
            </div>
            {!section.isMonth && (
              <div className="Answers">
                {section.answers.map((answer, index) => (
                  <div key={index} className="Answer">
                    {!singleTopic && <h3>{(prompts ?? {})[answer.prompt_id!]?.question ?? answer.prompt_question}</h3>}
                    <div className="Content">{answer.text}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <InfiniteLoader
          // key={`${height},${width}`}
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={itemCount}
        >
          {({ onItemsRendered, ref }) => (
            <List
              className="JournalList"
              height={height}
              itemCount={itemCount}
              itemSize={getRowHeight}
              ref={(r) => {
                listRef.current = r;
                return ref;
              }}
              width={width}
              onItemsRendered={onItemsRendered}
            >
              {Row}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

function Journal(
  props: {
    columnId?: string | null;
    promptId?: string | null;
    user?: firebase.User | null;
    boardId?: string | null;
    controller: BrightnessController | null;
  } & RouteComponentProps
) {
  const { promptIds, sections } = useSelector(selectJournal);
  const { reflection, board, boards, boardId, prompts } = useSelector(selectReflections);
  const plan = useSelector(selectPlan);
  const [ended, setEnded] = useState<boolean>(false);
  const [cursor, setCursor] = useState<firebase.firestore.QueryDocumentSnapshot<Answer> | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [openNewBoardModal, setOpenNewBoardModal] = useState<boolean>(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false);
  const columnsRef = createRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const singleTopic = promptIds.length === 1;
  const [onResize$, setOnResize] = useState<Subject<number[]>>();

  const listRef = useRef<any | null>(null);
  const rowHeights = useRef<{ [key: number]: number }>({});
  const totalAnswers = calculateTotalAnswers();

  const subscribed = isPro(plan);
  // const totalSections = calculateTotalSections();

  function calculateTotalAnswers(): number {
    return Object.values(prompts ?? {}).reduce((sum, el) => sum + (el.answers?.length ?? 0), 0);
  }

  function calculateAnswersInColumn(column: Column): number {
    if (!prompts) {
      return 0;
    }
    const promptsInColumn = (column.prompts ?? []).map((p) => prompts[p]);
    return promptsInColumn.reduce((sum, el) => sum + (el.answers?.length ?? 0), 0);
  }

  // function calculateTotalSections(): number {
  //   const set = new Set<string>([]);

  //   // add all answers and find unique dates = sections.
  //   if (props.promptId && prompts) {
  //     (prompts[props.promptId]?.answers ?? []).forEach((a) => set.add(a));
  //   } else {
  //     Object.values(prompts ?? {}).forEach((p) => (p.answers ?? []).forEach((a) => set.add(a)));
  //   }

  //   return set.size;
  // }

  function canCreateMore(): boolean {
    if (!subscribed && (boards?.length ?? 0) >= 3) {
      return false;
    }

    return true;
  }

  // https://codesandbox.io/s/react-chat-simulator-yg114?file=/src/ChatRoom/Room/Messages/Messages.js:527-537
  function getRowHeight(index: number) {
    if (index >= sections.length) {
      return 1200;
    }
    return rowHeights.current[index] ?? 200;
  }

  function setRowHeight(index: number, size: number) {
    // console.log(`cache row height ${index}, ${size}`);
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function updateDimensions() {
    onResize$?.next([]);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });

  function onBoard(event: MouseEvent<HTMLElement>) {
    navigate(`/app/${props.boardId}`);
  }

  async function getNextPage() {
    if (ended || fetching) {
      return;
    }

    // console.log("Get next page");

    if (!board?.id) {
      return;
    }

    setFetching(true);

    try {
      let results: firebase.firestore.QuerySnapshot<Answer>;

      let loadingBoard = board?.id;
      let loadingPromptIds = promptIds;

      if (promptIds.length === 0) {
        results = await boardJournalQuery(board?.id, PageSize, cursor).get();
      } else {
        results = await boardJournalQueryByPrompts(board?.id, promptIds, PageSize, cursor).get();
      }

      // make sure we're still at the same board and prompts
      if (
        loadingBoard !== board?.id ||
        loadingPromptIds.length !== promptIds.length ||
        loadingPromptIds[0] !== promptIds[0]
      ) {
        // user switched board or prompts. do nothing.
      } else {
        logInfo("Got another page");

        if (results.empty || results.size < PageSize) {
          setEnded(true);
        }

        if (results && !results.empty) {
          // save the last doc as cursor
          setCursor(results.docs[results.docs.length - 1]);
          const newAnswers: Answer[] = results.docs.map((d) => d.data()).filter((d) => Boolean(d)) as Answer[];

          patchAnswerEmptyField(results.docs);

          // infiniteLoaderRef.current?.resetloadMoreItemsCache(true);
          // rowHeights.current = {};
          listRef.current?.resetAfterIndex(0);

          dispatch(updateAnswers(newAnswers));
        }
      }
    } catch (error) {
      logError(error);
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    // force board
    const requestedBoardId = props.boardId ?? reflection?.defaultBoardId;
    if (requestedBoardId && requestedBoardId !== boardId) {
      dispatch(changeBoard(requestedBoardId));
      dispatch(invalidateJournal());
      setEnded(false);
      setCursor(null);
      setFetching(false);
      navigate(`/app/${requestedBoardId}/journal`);
    }
  }, [boardId, dispatch, props.boardId, reflection?.defaultBoardId]);

  useEffect(() => {
    if (!onResize$) {
      setOnResize(new Subject<number[]>());
      return;
    }

    const resizeSubscription = onResize$.pipe(debounceTime(500)).subscribe(() => {
      rowHeights.current = {};
      listRef.current?.resetAfterIndex(0);
    });

    return () => {
      resizeSubscription.unsubscribe();
    };
  }, [onResize$]);

  useEffect(() => {
    // rowHeights.current = {};
    listRef.current?.resetAfterIndex(0);
  }, [board?.id, prompts]);

  useEffect(() => {
    if (fetching) {
      return;
    }

    if (props.columnId && board?.columns) {
      const column = board.columns.find((b) => b.id === props.columnId);

      if (column && column.prompts && column.prompts.length > 0) {
        // check if prompt id changed. otherwise, we'll be in an infinite loop
        let promptIdsChanged = promptIds.length !== column.prompts.length;
        if (!promptIdsChanged) {
          // check inside the arrays
          const promptIdSet = new Set<string>(promptIds);
          const hasDiffItems = column.prompts.find((p) => !promptIdSet.has(p));
          promptIdsChanged = !!hasDiffItems;
        }

        if (promptIdsChanged) {
          dispatch(changePromptIds(column.prompts));
          setEnded(false);
          setCursor(null);
          return;
        }
      } else {
        // invalid column
        navigate(`/app/${board?.id}/journal`);
        return;
      }
    } else if (props.promptId && (promptIds.length === 0 || promptIds[0] !== props.promptId)) {
      dispatch(changePromptIds([props.promptId]));
      setEnded(false);
      setCursor(null);
      return;
    } else if (!props.promptId && promptIds.length !== 0) {
      dispatch(changePromptIds([]));
      setEnded(false);
      setCursor(null);
      return;
    }

    if (ended) {
      return;
    }
  }, [board?.columns, board?.id, dispatch, ended, fetching, promptIds, props.columnId, props.promptId]);

  function onToggleBrightness() {
    props.controller?.setDark(!(props.controller?.dark ?? false));
  }

  function onChangeBoard(value: string) {
    setEnded(false);
    setCursor(null);
    setFetching(false);

    if (value === NEW_BOARD_VALUE) {
      if (canCreateMore()) {
        setOpenNewBoardModal(true);
      } else {
        setOpenSubscriptionModal(true);
      }
      return;
    }

    if (value === "") {
      navigate(`/app/${reflection?.defaultBoardId ?? ""}/journal`);
      return;
    }

    navigate(`/app/${value}/journal`);
  }

  function isItemLoaded(index: number): boolean {
    // if infinite scroll is asking about an index larger than section length, then we trigger a load
    if (ended) {
      return true;
    }

    if (!fetching && index === sections.length) {
      getNextPage();
    }

    return false;
  }

  const selectedBoardId = board?.id ?? "";

  return (
    <div className={`JournalWrapper ${props.controller?.dark ?? false ? "Dark" : ""}`}>
      <div className="CanvasMenu">
        <div className="CanvasTitle">
          <img
            className="logo"
            src={`${process.env.PUBLIC_URL}/${props.controller?.dark ? "logo-dark.svg" : "logo.svg"}`}
            alt="Jot"
          />
          <div onClick={onToggleBrightness}>
            <IconButton aria-label="brightness" size="small">
              {props.controller?.dark ?? true ? <Icon icon="akar-icons:moon-fill" /> : <WbSunny />}
            </IconButton>
          </div>
        </div>
        <div className="CanvasControls">
          <div onClick={onBoard}>
            <IconButton aria-label="journal" size="small">
              <DashboardIcon />
            </IconButton>
          </div>
          {board?.id && <BoardSettings boardId={board?.id} />}
          <BoardSelector
            selectedBoardId={selectedBoardId}
            board={board}
            boards={boards}
            onChangeBoard={onChangeBoard}
            showNew={true}
          />
        </div>
      </div>
      <div className="Journal">
        <div className="Navigation">
          <Link
            color="inherit"
            to={`/app/${board?.id}/journal`}
            className={!props.promptId && !props.columnId ? "selected" : ""}
          >
            <div>All</div>
            {totalAnswers > 0 && <div className="Counts">{totalAnswers}</div>}
          </Link>
          {(board?.columns ?? []).length > 0 && (
            <div className="Books">
              {board?.columns?.map((c) => (
                <Link
                  key={c.id}
                  color="inherit"
                  to={`/app/${board?.id}/journal/b/${c.id}`}
                  className={c.id === props.columnId ? "selected" : ""}
                >
                  <div style={{ color: getTextColor(props.controller?.dark, c.color) ?? "inherit" }}>
                    {c.name ?? "Untitled Journal"}
                  </div>
                  {calculateAnswersInColumn(c) > 0 && <div className="Counts">{calculateAnswersInColumn(c)}</div>}
                </Link>
              ))}
            </div>
          )}
          <div className="Prompts">
            {Object.values(prompts ?? {}).map((p) => (
              <Link
                color="inherit"
                key={p.id}
                to={`/app/${board?.id}/journal/p/${p.id!}`}
                className={p.id === props.promptId ? "selected" : ""}
              >
                <div>{p.question}</div>
                {(p.answers?.length ?? 0) > 0 && <div className="Counts">{p.answers?.length}</div>}
              </Link>
            ))}
          </div>
        </div>
        {/* https://www.npmjs.com/package/react-window-infinite-loader */}
        <div className={`Columns ${singleTopic ? "SingleColumn" : ""}`} id="Columns" ref={columnsRef}>
          <JournalContent
            key={`${board?.id}${promptIds}`}
            hasNextPage={!ended}
            isNextPageLoading={fetching}
            sections={sections}
            // itemCount={totalSections}
            setRowHeight={setRowHeight}
            getRowHeight={getRowHeight}
            singleTopic={singleTopic}
            prompts={prompts}
            listRef={listRef}
            isItemLoaded={isItemLoaded}
            dark={props.controller?.dark}
          />
          {ended && sections.length === 0 && <div className="Empty">To be written</div>}
        </div>
      </div>
      {props.user && (
        <SubscriptionDialog
          open={openSubscriptionModal}
          uid={props.user.uid}
          onClose={() => setOpenSubscriptionModal(false)}
        />
      )}
      {props.user && (
        <NewBoardDialog open={openNewBoardModal} user={props.user} onClose={() => setOpenNewBoardModal(false)} />
      )}
    </div>
  );
}

function JournalWithUser({
  promptId,
  columnId,
  board,
}: { promptId?: string | null; columnId?: string | null; board?: string | null } & RouteComponentProps) {
  return (
    <UserConsumer>
      {(user) => {
        return (
          <BrightnessControllerConsumer>
            {(controller) => {
              return (
                <Journal user={user} boardId={board} promptId={promptId} columnId={columnId} controller={controller} />
              );
            }}
          </BrightnessControllerConsumer>
        );
      }}
    </UserConsumer>
  );
}

export default JournalWithUser;
